"use strict";

/*globals Kobo,ko,DynamicConfiguration*/
(function () {
  'use strict';

  function RgiSuperPointsBalanceVM() {
    this.isHidden = ko.observable(true);
    this.availablePoints = ko.observable(0);
    this.standardPoints = ko.observable(0);
    this.limitedTermPoints = ko.observable(0);
    this.pointsLabel = DynamicConfiguration.resourceStrings.rgiPoints;
    this.standardPointsLabel = DynamicConfiguration.resourceStrings.rgiStandardPoints;
    this.termLimitedPointsLabel = DynamicConfiguration.resourceStrings.rgiTermLimitedPoints;

    this.update = function (data) {
      this.availablePoints(data.AvailablePoints);
      this.standardPoints(data.StandardPoints);
      this.limitedTermPoints(data.LimitedTermPoints);
    };
  }

  Kobo.Utilities.assignToNamespace('Kobo.ViewModel.RichHeader.RgiSuperPointsBalanceVM', RgiSuperPointsBalanceVM);
})();