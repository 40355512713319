"use strict";

Kobo.ViewModel.Expandable.HeaderAccountButton = function (gizmo, options) {
  var self = this;
  self.$header = gizmo.$innerContent.find('> .facet-list-header');
  gizmo.$gizmo.prepend(self.$header);
  Kobo.ViewModel.Expandable.BaseButton.apply(self, arguments);
};

Kobo.ViewModel.Expandable.HeaderAccountButton.prototype = Kobo.chainPrototype(Kobo.ViewModel.Expandable.BaseButton.prototype);

Kobo.ViewModel.Expandable.HeaderAccountButton.prototype.addButton = function () {
  var self = this;
  Kobo.ViewModel.Expandable.BaseButton.prototype.addButton.call(self); //self.$subButton = Kobo.$("<div />").addClass("gizmo-expandable-subbutton gizmo-expandable-button-chevron");
  //self.$button.append(self.$subButton);
  //if (typeof self.$header !== 'undefined') {
  //    self.$header.append(this.$button);
  //}
  //self.$button.addClass('gizmo-expandable-button-chevron-wrapper');

  self.$button.addClass('gizmo-expandable-button-chevron');
};