"use strict";

/*global Kobo,$,console */
Kobo.Gizmo.Header = function (gizmo, options) {
  "use strict"; //variable declarations

  Kobo.Gizmo.apply(this, arguments);
  this.setType("Header");
  var self = this,
      $slideOutButton = self.$gizmo.find('.header-slideout-menu-button'),
      $mainMenu = self.$gizmo.find('.header-main-menu'),
      $mainNavBar = self.$gizmo,
      $headerSearchToggleButton = self.$gizmo.find('.search-bar-toggle'),
      $headerCartToggleButton = self.$gizmo.find('.icon-cart'),
      $headerCartCount = $headerCartToggleButton.find('em'),
      $flyoutCartCount = self.$gizmo.find('.small-cart-link .cart-item-count em'),
      $smallCartLink = self.$gizmo.find('.small-cart-link'),
      $animateWrap = Kobo.$('html,body'),
      $rakutenLogoBar = Kobo.$('.rakuten-logo-bar'),
      $searchSection = Kobo.$('.search-section'),
      $drawerInnerWrap = Kobo.$('.hero-drawer div'),
      $isCompleteCalled = false,
      setupHandlers;
  self.accountExpandableTriggerSmall = self.$gizmo.find(".account-expandable-trigger-small");
  self.accountExpandableTriggerLarge = self.$gizmo.find(".account-expandable-trigger-large");
  self.expandables = [];
  self.expandableSmall = null;
  self.expandableLarge = null;
  self.doToggleSearchBar = true;
  self.isSearchExpanded = false;
  self.isCartExpanded = false;
  self.doToggleCart = true; // Sticky header after Rakuten Bar and closing of hero widget when you scroll past it.

  Kobo.$window.on('scroll', function (evt) {
    var scroll = Kobo.$window.scrollTop(),
        topLogoOffset = $rakutenLogoBar.offset().top + $rakutenLogoBar.height(),
        searchSectionOffset,
        cartContentOffset; // handles rakuten logo  bar

    if (scroll > topLogoOffset) {
      Kobo.$body.addClass('header-fixed');
    } else {
      Kobo.$body.removeClass('header-fixed');
    }

    if ($searchSection.length > 0) {
      searchSectionOffset = $searchSection.offset().top + $searchSection.height(); // allows toggle if on page

      self.doToggleSearchBar = scroll < searchSectionOffset;
    }
  }); // menu open animation

  self.slideMenuOpen = function () {
    $mainMenu.removeClass('hide-flyout-menu').addClass('show-flyout-menu');
    Kobo.$body.removeClass('push-content-left').addClass('push-content-right');
    $mainNavBar.removeClass('slide-left').addClass('slide-right');
    $slideOutButton.addClass('flyout-active');
    $mainNavBar.removeClass('slide-left');
  }; // menu closed animation


  self.slideMenuClose = function () {
    $mainMenu.removeClass('show-flyout-menu').addClass('hide-flyout-menu');
    Kobo.$body.removeClass('push-content-right').addClass('push-content-left');
    $mainNavBar.removeClass('slide-right').addClass('slide-left');
    $slideOutButton.removeClass('flyout-active');
  };

  self.calculateHeight = function (currentSize) {
    if (currentSize === "large" || currentSize === "jumbo") {
      $mainMenu.css("overflow-y", "");
      $mainMenu.height("");
    } else {
      var windowHeight = Kobo.$(window).height(),
          menuHeight = 0;
      $mainMenu.children().each(function () {
        menuHeight += Kobo.$(this).outerHeight();
      });

      if (menuHeight > windowHeight) {
        $mainMenu.css("overflow-y", "scroll");
        $mainMenu.height(windowHeight);
      } else {
        $mainMenu.css("overflow-y", "");
        $mainMenu.height(menuHeight + windowHeight);
      }
    }
  };

  function toggleSearch() {
    self.fire('shoppingCart::toggle', {
      toggle: false
    });
    self.fire('toggleSearchHero', {
      isCollapsed: !$headerSearchToggleButton.hasClass('is-collapsed'),
      $searchSection: $searchSection,
      $mainNavBar: $mainNavBar
    });
  } // slideout menu handlers


  setupHandlers = function setupHandlers() {
    $slideOutButton.click(function (evt) {
      if ($mainNavBar.hasClass('slide-right')) {
        self.slideMenuClose();
      } else if ($mainNavBar.hasClass('slide-left')) {
        self.slideMenuOpen();
      } else {
        self.slideMenuOpen();
        $slideOutButton.addClass('flyout-active');
      }
    });
    self.events.onResize(function (event) {
      if (event.currentSize === "large" || event.currentSize === "jumbo") {
        self.slideMenuClose();
      }

      self.calculateHeight(event.currentSize);
    }); // toggle search drawer on click

    $headerSearchToggleButton.click(function (e) {
      e.preventDefault();

      if (self.doToggleSearchBar) {
        toggleSearch();
      } else {
        $isCompleteCalled = false;
        $animateWrap.animate({
          'scrollTop': '0'
        }, {
          duration: 400,
          complete: function complete() {
            if ($headerSearchToggleButton.hasClass('is-collapsed')) {
              $isCompleteCalled = true;
              $headerSearchToggleButton.removeClass('is-collapsed');
              toggleSearch();
            }

            setTimeout(function () {
              Kobo.$('.search-field').focus();
            }, 250);
          }
        });
      }
    });
    $headerCartToggleButton.click(function (event) {
      event.preventDefault();
      self.fire('toggleSearchHero', {
        toggle: false
      });
      self.fire('shoppingCart::toggle');
    });
    /*$headerCartToggleButton.click(function(event) {
        event.preventDefault();
          if (self.doToggleCart) {
            toggleCart();
        } else {
            $isCompleteCalled = false;
            $animateWrap.animate(
                {'scrollTop': '0'},
                {duration:400,
                    complete:function() {
                          if ($headerCartToggleButton.hasClass('is-collapsed'))
                        {
                              // $isCompleteCalled = true;
                            $isCompleteCalled = true;
                            $headerSearchToggleButton.removeClass('is-collapsed');
                            self.fire('toggleSearchHero', {
                                isCollapsed: $headerSearchToggleButton.hasClass('is-collapsed'),
                                $searchSection: $searchSection
                            });
                        }
                          setTimeout(function () {
                            Kobo.$('.search-field').focus();
                        }, 250);
                    }
                });
        }
    });*/

    self.subscribe("gizmosInitialized", function () {
      if (self.expandables.length === 0) {
        var expandables = Kobo.Gizmo.Expandable.getExpandableDescendants(self);

        if (expandables && expandables.length > 0) {
          self.expandables = expandables;
          self.expandableSmall = self.expandables[0];
          self.expandableLarge = self.expandables[1];
        } else {
          self.expandables = false;
        }
      }
    });
    self.accountExpandableTriggerSmall.on("click", function (event) {
      event.preventDefault();

      if (self.expandableSmall) {
        self.expandableSmall.viewModel.toggle();
      }
    });
    self.accountExpandableTriggerLarge.on("click", function (event) {
      event.preventDefault();

      if (self.expandableLarge) {
        self.expandableLarge.viewModel.toggle();
      }
    });
    self.subscribe("shoppingCartItems::updated", function (eventName, data) {
      var itemCount = data.detail.items && data.detail.items.length;

      if (itemCount > 0) {
        var count = itemCount > 99 ? '99+' : itemCount;
        $headerCartCount.text(count);
        $flyoutCartCount.text(count);
        $headerCartToggleButton.addClass('show-icon');
        $flyoutCartCount.css("display", "inline-block"); //.css('display', 'inline-block');
      } else {
        $headerCartToggleButton.removeClass('show-icon');
        $flyoutCartCount.css("display", "none"); //$headerCartCount.text("0").css('display', '');
      }
    });
  };

  self.init = function () {
    setupHandlers();
  };

  self.init();
};

Kobo.Gizmo.Header.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);