"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

/*global Kobo,ko,$,jQuery*/
ko.observable.fn.pauseable = function () {
  "use strict";

  var self = this;
  this.pauseNotifications = false;

  this.notifySubscribers = function (value, event) {
    if (!self.pauseNotifications) {
      ko.subscribable.fn.notifySubscribers.call(this, value, event);
    }
  };

  return this;
};

ko.observableArray.fn.pushAll = function (valuesToPush) {
  var underlyingArray = this();
  this.valueWillMutate();
  ko.utils.arrayPushAll(underlyingArray, valuesToPush);
  this.valueHasMutated();
  return this;
};

ko.observableArray.fn.replaceAll = function (valuesToPush) {
  var underlyingArray = this();
  this.valueWillMutate();
  underlyingArray.splice(0, underlyingArray.length); // remove all

  ko.utils.arrayPushAll(underlyingArray, valuesToPush);
  this.valueHasMutated();
  return this;
};

ko.subscribable.fn.subscribeChanged = function (callback, callbackTarget) {
  "use strict";

  var previousValue;
  var before = this.subscribe(function (_previousValue) {
    previousValue = _previousValue;
  }, callbackTarget, 'beforeChange');
  var after = this.subscribe(function (latestValue) {
    callback.call(callbackTarget, latestValue, previousValue);
  }, callbackTarget);
  return {
    dispose: function dispose() {
      before.dispose();
      after.dispose();
    }
  };
};

if (_typeof(window.Kobo) !== "object") {
  /**
   * Kobo
   * @type {{clone: Function, deepCopy: Function, scheduleAction: Function}}
   */
  window.Kobo = function (jQuery, window) {
    "use strict";

    var $window = jQuery(window),
        $html = jQuery("html"),
        $body = jQuery("body"),
        $document = jQuery(document),
        TEMP = function TEMP() {},
        logAltFunction = function logAltFunction(msg) {
      if (Kobo) {
        if (window.__koboLogHistory__ && window.__koboLogHistory__.length > 0) {
          Kobo.logHistory = Kobo.$.extend([], window.__koboLogHistory__);
          window.__koboLogHistory__ = undefined;
        } else if (!Kobo.logHistory) {
          Kobo.logHistory = [];
        }

        Kobo.logHistory.push(msg);
      } else {
        if (!window.__koboLogHistory) {
          window.__koboLogHistory = [];
        }

        window.__koboLogHistory.push(msg);
      }
    },
        logSetup = function logSetup(window, isLogEnabled) {
      if (isLogEnabled) {
        var logHistory = function logHistory() {
          Kobo.logHistory.push(arguments);
        },
            con,
            fp,
            result;

        try {
          con = window.console;
          fp = Function.prototype;

          if (con && con.log && fp.bind) {
            result = con.log.bind(con);
          } else {
            result = logAltFunction;
          }

          return result;
        } catch (e) {
          /* TODO Fix me!! I'm using Kobo._error before its defined!
           if (typeof Kobo._error === "object") {
           Kobo._error.error("error.log", "Kobo", this, "console.log or  Array.prototype.slice or Function.prototype.bind don't exist.");
           }*/

          /* TODO Kobo is undefined in IE8 for some reason?
           Kobo.logHistory = [];
           return logHistory;*/
          return logAltFunction;
        }
      } else {
        return logAltFunction;
      }
    };

    jQuery(function () {
      // If this file is included in the <head> section then Kobo.$body will be empty and we have to wait
      // till document gets loaded to redefine it.
      if (Kobo.$body.length === 0) {
        Kobo.$body = jQuery("body");
      }
    });

    function setValue(tag, tagName, value) {
      if (typeof value === "string") {
        // if tagname is type input then use .val otherwise use .text
        var inputPattern = /input/i;

        if (inputPattern.test(tagName)) {
          $(tag).val(value);
        } else {
          $(tag).text(value);
        }
      }
    }

    function setClasses(tag, classes) {
      if (classes.length > 0) {
        for (var i in classes) {
          if (typeof classes[i] === "string") {
            tag.addClass(classes[i]);
          }
        }
      }
    }

    function setAttributes(tag, attributes) {
      if (this.ObjectLength(attributes) > 0) {
        for (var k in attributes) {
          if (typeof attributes[k] === "string") {
            var attr = {};
            attr[k] = attributes[k];
            tag.attr(attr);
          }
        }
      }
    }

    function setupEvents(tag, events) {
      for (var i in events) {
        if (typeof events[i] === "function") {
          var click = /click/i;
          var hover = /hover/i;

          if (click.test(i)) {
            $(tag).on("click", events[i]);
          } else if (hover.test(i)) {
            $(tag).on("hover", events[i]);
          }
        }
      }
    }

    return {
      $: jQuery,
      $window: $window,
      $html: $html,
      $body: $body,
      $document: $document,
      logEnable: function logEnable() {
        Kobo.log = logSetup(window, true);
      },
      logDisable: function logDisable() {
        Kobo.log = logSetup(window, false);
      },
      log: logSetup(window, window.DynamicConfiguration.isLogEnabled),
      chainPrototype: function chainPrototype(prototype) {
        TEMP.prototype = prototype;
        return new TEMP();
      },
      clone: function clone(object) {
        var key,
            newObject = {};

        for (key in object) {
          if (object.hasOwnProperty(key)) {
            newObject[key] = object[key];
          }
        }

        return newObject;
      },
      getGizmo: function getGizmo(gid) {
        return Kobo.$("[data-gid='" + gid + "']").data('instance');
      },

      /**
       * deepCopy -
       * @param obj
       * @return {*}
       */
      deepCopy: function deepCopy(obj) {
        // wraps jQuery extend method
        return Kobo.$.extend({}, obj);
      },
      extend: function extend() {
        // wraps jQuery extend method
        return Kobo.$.extend.apply(this, arguments);
      },
      typeOf: function typeOf(o) {
        var stringType = Object.prototype.toString.call(o),
            type = stringType.match(/\[object (\w*)\]/)[1].toLowerCase();
        return type === 'number' && isNaN(o) ? 'NaN' : type;
      },

      /**
       * @typedef {object} ScheduleActionParams
       * @param {function} fn Main function to be called
       * @param {int} times
       * @param {int} timeout
       * @param {function} [callback] A callback function to be called on completion
       * @param {int} [timeoutId] An id of setTimeout to be used outside for canceling
       */

      /**
       * Executes some function given amount of times
       * @param {ScheduleActionParams} params
       */
      scheduleAction: function scheduleAction(params) {
        if (!params || !params.fn || !params.timeout || typeof params.times === 'undefined') {
          Kobo._error.error('MandatoryParamsMissing', 'Kobo.scheduleAction', Kobo.scheduleAction.toString(), {
            msg: 'Not all mandatory parameters were passed. Expected: {fn, times, timeout}. Received: see data for arguments',
            data: arguments
          });

          return;
        }

        params.timeoutId = setTimeout(function () {
          if (--params.times >= 0) {
            params.fn();
            Kobo.scheduleAction(params);
          } else {
            if (typeof params.callback === 'function') {
              params.callback();
            }
          }
        }, params.timeout);
      },
      //TODO: return error if mapping is incorrect.
      subscribeObservablesFromTo: function subscribeObservablesFromTo(from, to, map, notifyInitialValue) {
        var mapFrom = map || from,
            i,
            j,
            builder = function builder(i, j) {
          return function (i, j) {
            from[i].subscribe(function (newValue) {
              to[j](newValue);
            });

            if (notifyInitialValue) {
              to[j](from[i]());
            }
          }(i, j);
        };

        for (i in mapFrom) {
          if (mapFrom.hasOwnProperty(i)) {
            i = Kobo.typeOf(mapFrom) === 'array' ? mapFrom[i] : i;
            j = map && map[i] || i;

            if (ko.isObservable(from[i]) && ko.isObservable(to[j])) {
              builder(i, j);
            }
          }
        }
      },
      Formatters: {
        dateMonthYear: function dateMonthYear(date, format) {
          var dateObj, dateString;

          if (date && date.match(/date/i)) {
            dateObj = new Date(parseInt(date.substr(6), 10));
            dateString = Kobo.Formatters.monthNames[dateObj.getMonth()].toUpperCase() + ' ' + dateObj.getFullYear();
            return dateString;
          }

          return date;
        },
        monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
      },
      // Cross browser, mostly IE<9 way of getting length of object
      ObjectLength: function ObjectLength(obj) {
        var i,
            count = 0;

        if (obj) {
          for (i in obj) {
            if (obj.hasOwnProperty(i)) {
              count = count + 1;
            }
          }
        }

        return count;
      },
      buildTag: function buildTag(tagAttributes) {
        var tag, classes, attributes, value, events;

        if (typeof tagAttributes.tagName !== "string") {
          return false;
        }

        tag = $(document.createElement(tagAttributes.tagName)); //set classes

        classes = tagAttributes.classes;
        setClasses.call(this, tag, classes); //set attributes

        attributes = tagAttributes.attributes;
        setAttributes.call(this, tag, attributes); // set value

        value = tagAttributes.value;
        setValue.call(this, tag, tagAttributes.tagName, value); //set events

        events = tagAttributes.events;
        setupEvents.call(this, tag, events);
        return tag;
      }
    };
  }(jQuery, window);
}