"use strict";

/*global Kobo,$,console */
Kobo.Gizmo.GroupLink = function (options) {
  'use strict';

  var self = this,
      ulItems,
      ulFeaturedItems,
      maxLink,
      maxFeaturedList,
      init;
  Kobo.Gizmo.apply(this, arguments); // inherit from base class

  this.setType('GroupLink');

  self.showHideLinks = function () {
    var screenMode = Kobo._mediator.determineBreakpoint(),
        i,
        j;

    if (screenMode === "small" || screenMode === "medium") {
      maxLink = parseInt(self.maxSmallViewLinks, 10);
      maxFeaturedList = parseInt(self.maxSmallViewFeaturedListLinks, 10);
    }

    for (i = 0; i < ulItems.length; i++) {
      if (i < maxLink) {
        Kobo.$(ulItems[i]).show();
      } else {
        Kobo.$(ulItems[i]).hide();
      }
    }

    for (j = 0; j < ulFeaturedItems.length; j++) {
      if (j < maxFeaturedList) {
        Kobo.$(ulFeaturedItems[j]).show();
      } else {
        Kobo.$(ulFeaturedItems[j]).hide();
      }
    }
  };

  self.events.onResize(function () {
    self.showHideLinks();
  });

  init = function init() {
    self.maxSmallViewLinks = options.maxSmallViewLinks;
    self.maxLargeViewLinks = options.maxLargeViewLinks;
    self.maxSmallViewFeaturedListLinks = options.maxSmallViewFeaturedListLinks;
    self.maxLargeViewFeaturedListLinks = options.maxLargeViewFeaturedListLinks;
    self.linkSelector = options.linkSelector;
    self.featureLinkSelector = options.featureLinkSelector;
    ulItems = self.$gizmo.find('.' + self.linkSelector);
    ulFeaturedItems = self.$gizmo.find('.' + self.featureLinkSelector);
    maxLink = parseInt(self.maxLargeViewLinks, 10);
    maxFeaturedList = parseInt(self.maxLargeViewFeaturedListLinks, 10);
    self.showHideLinks();
  };

  init();
};

Kobo.Gizmo.GroupLink.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);