"use strict";

/*global Kobo,$,console */
(function (Kobo) {
  function GizmoRichHeaderLanguageSelector() {
    'use strict';

    Kobo.Gizmo.apply(this, arguments);
    this.setType('RichHeaderLanguageSelector');
    this.$languageSelectorButton = this.$gizmo;
    this.$languageSelectorDropDownMenu = this.$gizmo.find('.' + this.settings.languageSelectorDropDownMenuSelector);
    this.$languageSelectorShowDropdown = this.settings.languageSelectorShowDropdownSelector;
    this.$languageSelectorExpanded = this.settings.languageSelectorExpandedSelector;
    this.$languageSelectorCollapsed = this.settings.languageSelectorCollapsedSelector;
    this.$languageSelectorButtonSelector = this.$gizmo.find('.' + this.settings.languageSelectorButtonSelector);
    this.setupHandlers();
  }

  ;
  GizmoRichHeaderLanguageSelector.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);

  GizmoRichHeaderLanguageSelector.prototype.expandLanguageDropdown = function () {
    this.$languageSelectorDropDownMenu.addClass(this.$languageSelectorShowDropdown);
    this.$languageSelectorButton.removeClass(this.$languageSelectorCollapsed).addClass(this.$languageSelectorExpanded);
    this.$languageSelectorButtonSelector.attr('aria-expanded', true);
  };

  GizmoRichHeaderLanguageSelector.prototype.collapseLanguageDropdown = function () {
    this.$languageSelectorDropDownMenu.removeClass(this.$languageSelectorShowDropdown);
    this.$languageSelectorButton.addClass(this.$languageSelectorCollapsed).removeClass(this.$languageSelectorExpanded);
    this.$languageSelectorButtonSelector.attr('aria-expanded', false);
  };

  GizmoRichHeaderLanguageSelector.prototype.setupHandlers = function () {
    var _this = this;

    this.$languageSelectorButton.on('click', function () {
      if (_this.$languageSelectorButton.hasClass(_this.$languageSelectorCollapsed)) {
        _this.expandLanguageDropdown();
      } else {
        _this.collapseLanguageDropdown();
      }
    }); // Hide when user clicks outside the dropdown

    Kobo.$(document).on('click', function (event) {
      if (Kobo.$(event.target).closest(_this.$languageSelectorButton).length === 0) {
        _this.collapseLanguageDropdown();
      }
    });
  };

  Kobo.Gizmo.RichHeaderLanguageSelector = GizmoRichHeaderLanguageSelector;
})(Kobo);