"use strict";

/*global Kobo,ko*/
// Global initialization script
// To avoid circular dependencies, certain actions cannot be done until all scripts have been loaded.
// An example is instantiation of global singletons.
// When this file executes, we assume all necessary scripts have been loaded.
// As far as possible, avoid adding code to this file. Initialization should be done in individual modules.
Kobo.$(function () {
  'use strict';
  /**
   * Modernizr.touchevents does not accurately detect the touch capabilities for the Sony desktop app,
   * which is based on QtWebkit. Kobo.Utilities.isTouch() is a more accurate test, and we undo
   * the effects of Modernizr if that turns out to be false.
   * This has to be done before the carousels initialize.
   */

  if (Kobo.Utilities && Kobo.Utilities.isTouch() === false) {
    Kobo.$('html').removeClass('touch');
  }

  Kobo._tracker = Kobo.Tracker ? new Kobo.Tracker(Kobo.$) : {};

  if (Kobo.Error) {
    Kobo._error = new Kobo.Error();
  } // Kobo._gizmo.gizmoList contains a reference to all the gizmo objects grouped by type
  // Kobo._gizmo.total is the total # of gizmo objects


  if (Kobo.GizmoFactory) {
    Kobo._gizmo = new Kobo.GizmoFactory();

    Kobo._gizmo.initContext(document.body, undefined);

    Kobo._gizmo.fire("gizmosInitialized", {
      "gizmoFactory": Kobo._gizmo
    });
  }
}); // Need to initialize the validation plugin with our custom
// settings during page load before any bindings are applied

if (ko.validation) {
  ko.validation.init({
    errorsAsTitle: false,
    // Don't need this
    errorMessageClass: 'validation-error-message',
    insertMessages: false,
    // We supply our own error message containers
    errorElementClass: 'validation-error',
    // Our custom error class for all fields
    allowHtmlMessages: true
  });
}