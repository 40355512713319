"use strict";

/*global Kobo */
Kobo.Gizmo.RichHeaderEReadersAndAppsPanel = function (gizmo, options) {
  'use strict';

  var self = this,
      init,
      defaultInfo,
      menuItems,
      wrapperClass,
      activeClass;
  Kobo.Gizmo.apply(self, arguments);
  self.setType("RichHeaderEReadersAndAppsPanel");

  self.activateItem = function (item) {
    Kobo.$(item).closest('.' + wrapperClass).siblings().removeClass(activeClass);
    Kobo.$(item).closest('.' + wrapperClass).addClass(activeClass);
  };

  self.selectItem = function (event) {
    self.activateItem(event.target);
  };

  self.applyDefaultSelection = function () {
    self.activateItem(defaultInfo);
  };

  self.setupHandlers = function () {
    menuItems.each(function () {
      Kobo.$(this).on('click', self.selectItem);
    });
  };

  init = function init() {
    defaultInfo = self.$gizmo.find('.' + options.defaultSelection);
    menuItems = self.$gizmo.find('.' + options.menuItemSelector);
    wrapperClass = options.itemWrapperClass;
    activeClass = options.activeClass;
    self.setupHandlers();
    self.applyDefaultSelection();
  };

  init();
};

Kobo.Gizmo.RichHeaderEReadersAndAppsPanel.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);