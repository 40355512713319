"use strict";

ko.bindingHandlers.externalElementClicked = ko.bindingHandlers.externalElementClicked || {
  init: function init(element, valueAccessor) {
    var $element = Kobo.$(element);
    Kobo.$('html').on('click', _onClick);
    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      Kobo.$('html').off('click', _onClick);
    });

    function _onClick(event) {
      var clickedOutsideOfElement = Kobo.$(event.target).closest($element).length === 0;

      if (clickedOutsideOfElement) {
        var action = valueAccessor();
        action(event);
      }
    }
  }
};