"use strict";

/*globals Kobo */
(function () {
  'use strict';

  Kobo.Utilities = Kobo.Utilities || {};
  Kobo.Utilities.Events = {
    DEBOUNCE_WAIT: 75,
    // Returns a function, that, as long as it continues to be invoked, will not
    // be triggered. The function will be called after it stops being called for
    // N milliseconds. If `immediate` is passed, trigger the function on the
    // leading edge, instead of the trailing.
    debounce: function debounce(func, wait, immediate) {
      var timeout;
      return function () {
        var context = this,
            args = arguments;

        var later = function later() {
          timeout = null;

          if (!immediate) {
            func.apply(context, args);
          }
        };

        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait || Kobo.Utilities.Events.DEBOUNCE_WAIT);

        if (callNow) {
          func.apply(context, args);
        }
      };
    }
  };
})();