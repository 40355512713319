"use strict";

/*global Kobo, $, window, ko, Cookies */
Kobo.Gizmo.CookieConsent = function (gizmo) {
  "use strict";

  var self = this,
      useRecommendedSettings,
      declineRecommendedSettings,
      init;
  Kobo.Gizmo.apply(self, arguments);

  self.persistCookieConsent = function () {
    Cookies.set('PersistentCookieConsent', Kobo.$.param({
      cookieConsent: true
    }), {
      expires: 365,
      path: '/',
      raw: true
    });
    Kobo.$('#CookieConsent').hide("slow");
  };

  self.persistGDPRCookieConsent = function () {
    useRecommendedSettings();
  };

  self.persistGDPRCookieDecline = function () {
    declineRecommendedSettings();
  };

  useRecommendedSettings = function useRecommendedSettings() {
    Kobo.Ajax({
      url: self.settings.useRecommendedSettingsUrl,
      method: 'POST',
      traditional: true,
      dataType: 'json',
      complete: function complete() {
        Kobo.$('#CookieConsentGdpr').hide();
        location.reload();
      }
    });
  };

  declineRecommendedSettings = function declineRecommendedSettings() {
    Kobo.Ajax({
      url: self.settings.declineRecommendedSettingsUrl,
      method: 'POST',
      traditional: true,
      dataType: 'json',
      complete: function complete() {
        Kobo.$('#CookieConsentGdpr').hide();
      }
    });
  };

  init = function init() {
    ko.applyBindings(self, gizmo);
    Kobo.$('#cookieConsentCta').on('click', self.persistCookieConsent);
    Kobo.$('#gdprCookieConsentCta').on('click', self.persistGDPRCookieConsent);
    Kobo.$('#gdprDeclineCta').on('click', self.persistGDPRCookieDecline);
    self.register('Gdpr::useRecommendedSettings');
    self.register('Gdpr::declineRecommendedSettings');
  };

  init();
};

Kobo.Gizmo.CookieConsent.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);