"use strict";

// The knockout event binding doesn't play well with touchend + click on the same element (click stops working), so we're defining our own here.
ko.bindingHandlers.touchEnd = ko.bindingHandlers.touchEnd || {
  init: function init(element, valueAccessor) {
    var $element = Kobo.$(element);
    var handler = valueAccessor();

    if (handler) {
      $element.on('touchend', handler);
    }
  }
};