"use strict";

(function () {
  Kobo.Utilities.assignToNamespace('Kobo.RichHeader.RichHeaderTabNavigationVM', RichHeaderTabNavigationVM);

  function RichHeaderTabNavigationVM(options) {
    var settings = Kobo.$.extend({
      tabSelectDelay: 150,
      ignoreTabClickDelay: 500
    }, options);
    var self = this;
    var tabSelectTimer;
    var ignoreTabClickTimer;
    var delaySelectTabDeferred;
    this.selectedTab = ko.observable(null);
    this.breakpoint = ko.observable(null);
    this.listenToTabClicks = ko.observable(true);
    this.ignoreHoverEventsBecauseOfTouchScreen = ko.observable(false);
    this.touchTabClicked = ko.observable(true);
    this.headerNavigatorPopupVM = ko.observable({
      cssClass: 'small-and-medium-view',
      links: [{
        text: DynamicConfiguration.resourceStrings.closeMenuNavigateToHeader,
        destinationId: 'small-header',
        callBack: function callBack() {
          Kobo._mediator.fire('smallSideMenu::toggle');

          return true;
        }
      }, {
        text: DynamicConfiguration.resourceStrings.closeMenuNavigateToContent,
        destinationId: 'main-content',
        callBack: function callBack() {
          Kobo._mediator.fire('smallSideMenu::toggle');

          return true;
        }
      }]
    });
    this.listenToHoverEvents = ko.computed(function () {
      if (self.ignoreHoverEventsBecauseOfTouchScreen()) {
        // always ignore hover events (regardless of screen size) because this is a touchscreen device
        return false;
      }

      var breakpoint = self.breakpoint();
      return breakpoint === Kobo.Breakpoints.large || breakpoint === Kobo.Breakpoints.jumbo;
    });

    this.tabClicked = function (tabId, url) {
      if (self.listenToTabClicks() === false) {
        return;
      }

      var selectedTab = self.selectedTab(); // clicking an already open tab will close it

      var newSelectedTab = selectedTab !== tabId ? tabId : null;
      self.selectedTab(newSelectedTab || null); // first touch will keep the tab open instead of navigation

      if (self.touchTabClicked() === false) {
        if (newSelectedTab === null) {
          self.touchTabClicked(true);
        }
      } else {
        self.touchTabClicked(false);
      }

      var breakpoint = self.breakpoint();

      if (breakpoint === Kobo.Breakpoints.large || breakpoint === Kobo.Breakpoints.jumbo) {
        if (!Modernizr.touch_events || !Modernizr.pointerevents) {
          // second touch will navigate
          if (self.touchTabClicked() === true) {
            Kobo.Utilities.navigateTo(url);
          }
        }
      }
    };

    this.tabHoverStart = function (tabId) {
      if (self.listenToHoverEvents() === false) {
        return;
      }

      self.touchTabClicked(false);

      var tabSelectedPromise = self._delaySelectTab(tabId);

      tabSelectedPromise.done(function () {
        self._brieflyIgnoreTabClicks();
      });
    };

    this.tabHoverEnd = function () {
      if (self.listenToHoverEvents() === false) {
        return;
      }

      self.touchTabClicked(true);

      self._delaySelectTab(null);
    };

    this.tabTouchEnd = function () {
      if (self.ignoreHoverEventsBecauseOfTouchScreen() === false) {
        // This is reproducing behavior from Gizmo.HeaderNavigationTab.js which presumably fixes a bug.
        // It looks like the original author was using the "touchend" event to detect a touchscreen device (like tablets).
        // Best guess is that there was an issue where on certain touchscreen devices hover start and end were quickly firing before click during a touch, so the menu wouldn't open.
        self.ignoreHoverEventsBecauseOfTouchScreen(true); // If a delayed tab selection was in progress when this occurred, cancel it

        self._cancelDelaySelectionOfTab();
      }
    };

    this.closeAll = function (e) {
      self._cancelDelaySelectionOfTab();

      self.selectedTab(null);

      if (e) {
        Kobo._mediator.fire('smallSideMenu::close', {
          targetElement: e.target
        });
      }
    };

    this._delaySelectTab = function (tabId) {
      self._cancelDelaySelectionOfTab();

      if (delaySelectTabDeferred) {
        delaySelectTabDeferred.reject();
        delaySelectTabDeferred = null;
      }

      var deferred = Kobo.$.Deferred();
      tabSelectTimer = setTimeout(function () {
        self.selectedTab(tabId);
        deferred.resolve();
      }, settings.tabSelectDelay);
      delaySelectTabDeferred = deferred;
      return deferred.promise();
    };

    this._cancelDelaySelectionOfTab = function () {
      clearTimeout(tabSelectTimer);
    };

    this._brieflyIgnoreTabClicks = function () {
      clearTimeout(ignoreTabClickTimer);
      self.listenToTabClicks(false);
      setTimeout(function () {
        self.listenToTabClicks(true);
      }, settings.ignoreTabClickDelay);
    };
  }
})();