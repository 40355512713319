"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

/*global Kobo*/
if (_typeof(Kobo.Object) !== "object") {
  /**
   *
   * @type {{isNullOrEmpty: Function, parseBool: Function, typeOf: Function}}
   */
  Kobo.Object = {
    queryStringToJson: function queryStringToJson(queryString) {
      var string,
          tempArray,
          stringArray,
          stringArrayLength,
          result = {},
          i;

      if (queryString === undefined) {
        string = window.location.search.substr(1);

        if (Kobo.Object.isNullOrEmpty(string)) {
          return false;
        }
      } else {
        string = queryString.replace(/.*\?/, '');
      }

      stringArray = string.split('&');
      stringArrayLength = stringArray.length;

      for (i = 0; i < stringArrayLength; ++i) {
        tempArray = stringArray[i].split('=');
        result[decodeURI(tempArray[0])] = decodeURI(tempArray[1]);
      }

      return Kobo.Object.typeOf(result) === "object" ? result : false;
    },
    isNullOrEmpty: function isNullOrEmpty(value) {
      "use strict";

      switch (value) {
        case null:
        case "null":
        case undefined:
        case "":
          return true;

        default:
          return false;
      }
    },
    parseBool: function parseBool(obj) {
      "use strict";

      if (!!obj) {
        if (obj === true || obj.toLowerCase() === "true" || obj.toLowerCase() === "yes" || parseFloat(obj) === 1) {
          return true;
        }
      }

      return false;
    },
    typeOf: function typeOf(value) {
      "use strict";

      var s = _typeof(value);

      if (s === 'object') {
        if (value) {
          if (value instanceof Array) {
            s = 'array';
          }
        } else {
          s = 'null';
        }
      }

      return s;
    },
    resolveKoboNamespace: function resolveKoboNamespace(namespace) {
      "use strict";

      return this.resolveNamespace(namespace, 'Kobo');
    },
    resolveNamespace: function resolveNamespace(namespace, base) {
      "use strict";

      var names = namespace.split('.'),
          obj = base && window[base] || window,
          i,
          name;

      if (!namespace) {
        return false;
      }

      for (i = 0; i < names.length; i++) {
        name = names[i];

        if (obj[name]) {
          obj = obj[name];
        } else {
          return false;
        }
      }

      return obj;
    }
  };
}