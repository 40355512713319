"use strict";

/*global Kobo*/

/**
 * Kobo.Gizmo.Collapsible.IsList
 * @description Extends Kobo.Gizmo.Collapsible.HasHeader to act the same as a select list, with keyboard accessibility etc.
 * @param {element} el
 * @param {object} options
 */
Kobo.Gizmo.Collapsible.IsList = function () {
  'use strict';

  var self = this,
      init,
      button;
  Kobo.Gizmo.Collapsible.HasHeader.apply(this, arguments);

  init = function init() {
    if (!self.options.toggleClass) {
      throw new Error('Collapsible.HasHeader requires a toggle!');
    }

    button = self.$el.children('.' + self.options.toggleClass);

    if (!button.attr('aria-controls')) {
      throw new Error('Accessibility constraints violated: toggle must specify aria-controls (' + self.settings.name + ')');
    }

    if (!self.options.internalElementClass) {
      throw new Error('Internal element class must be specified (' + self.settings.name + ')');
    }
  };

  init();
};

Kobo.Gizmo.Collapsible.IsList.prototype = Kobo.chainPrototype(Kobo.Gizmo.Collapsible.HasHeader.prototype);