"use strict";

/*globals Kobo,ko,DynamicConfiguration */
Kobo.Gizmo.RichHeaderAudiobookCreditBalance = function (el, options) {
  'use strict';

  var self = this,
      viewModel,
      getAudiobookCreditBalance,
      init;
  Kobo.Gizmo.apply(this, arguments);
  this.setType("RichHeaderAudiobookCreditBalance");
  viewModel = new Kobo.ViewModel.RichHeader.AudiobookCreditBalanceVM();

  getAudiobookCreditBalance = function getAudiobookCreditBalance() {
    Kobo.Ajax({
      url: self.settings.getCreditBalanceUrl,
      method: 'GET',
      traditional: true,
      dataType: 'json',
      success: function success(data) {
        if (data !== null && data.CreditBalance !== null) {
          self.fire('audiobookCreditBalance::get', {
            creditAmount: data.CreditBalance
          });
          viewModel.creditBalance(data.CreditBalance);
        } else {
          viewModel.creditBalance(DynamicConfiguration.resourceStrings.notApplicable);
        }

        viewModel.isHidden(false);
      },
      error: function error() {
        viewModel.isHidden(true);
      }
    });
  };

  init = function init() {
    Kobo._mediator.subscribe('creditRedemption::end', getAudiobookCreditBalance);

    Kobo._mediator.register('audiobookCreditBalance::get');

    getAudiobookCreditBalance();
  };

  ko.applyBindings(viewModel, self.el);
  init();
};

Kobo.Gizmo.RichHeaderAudiobookCreditBalance.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);