"use strict";

// jquery.browser.js
// A hack to add $.browser.opera back to jQuery
// The field is a dependency for one of our HPCI purchase path scripts
(function ($) {
  $.browser = {
    opera: false,
    DO_NOT_USE: true
  };
})(jQuery);