"use strict";

(function (Kobo) {
  function GizmoRichHeaderTabNavigation(element) {
    Kobo.Gizmo.apply(this, arguments);
    this.setType("RichHeaderTabNavigation");
    var viewModel = new Kobo.RichHeader.RichHeaderTabNavigationVM();
    ko.applyBindings(viewModel, element);
  }

  GizmoRichHeaderTabNavigation.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);
  Kobo.Gizmo.RichHeaderTabNavigation = GizmoRichHeaderTabNavigation;
})(Kobo);