"use strict";

/*global Kobo, Collapsible*/

/**
 * Kobo.Gizmo.Collapsible
 * @description Creates a collapsible region that can animate to open and closed states using events
 * @param {element} el
 * @param {object} options
 */
Kobo.Gizmo.Collapsible = function () {
  'use strict';

  var self = this,
      coreCollapsible,
      getOpenSetting,
      initOpenSetting,
      handleEvent,
      closeAllOther,
      onResize,
      setupHandlers,
      init,
      currentBreakpoint;
  self.setDefaults({
    isOpen: false,

    /* can also be an object in the form of isOpen: { "small": true, "medium": false, etc. } */
    name: '',
    ignoreExternalCloseRequests: false
  });
  Kobo.Gizmo.apply(self, arguments);
  currentBreakpoint = self.currentBreakpoint();

  getOpenSetting = function getOpenSetting() {
    var isOpen;

    if (Kobo.typeOf(self.settings.isOpen) === "object") {
      isOpen = Kobo.Object.parseBool(self.settings.isOpen[self.currentBreakpoint()]);
    } else {
      isOpen = Kobo.Object.parseBool(self.settings.isOpen);
    }

    return isOpen;
  };

  initOpenSetting = function initOpenSetting() {
    if (getOpenSetting()) {
      coreCollapsible.open();
    } else {
      coreCollapsible.close();
    }
  };

  handleEvent = function handleEvent(event, data) {
    if (data.detail && data.detail.name === self.settings.name) {
      switch (event.type) {
        case 'collapsible::isOpen':
          // The way we have implemented events doesn't allow a return value here
          // So we have to use a callback function to get the isOpen property
          // Eg. fire('...', { callback: function (isOpen) { do something with isOpen } }
          if (data.detail.callback) {
            data.detail.callback(self.isOpen());
          }

          break;

        case 'collapsible::toggle':
          self.toggle();
          break;

        case 'collapsible::open':
          self.open();
          break;

        case 'collapsible::close':
          self.close();
          break;

        case 'collapsible::reset':
          self.reset();
          break;

        default:
          // Do nothing, we don't recognize this event (this is probably a programmer error)
          break;
      }
    }
  };

  closeAllOther = function closeAllOther(event, data) {
    if (data.detail && data.detail.name !== self.settings.name && !self.settings.ignoreExternalCloseRequests) {
      self.close();
    }
  };

  onResize = Kobo.Utilities.Events.debounce(function () {
    var newBreakpoint = self.currentBreakpoint();

    if (currentBreakpoint !== newBreakpoint) {
      currentBreakpoint = newBreakpoint;

      Kobo._mediator.fire('collapsible::reset', {
        name: self.settings.name
      });
    }
  });

  self.isOpen = function () {
    return coreCollapsible.isOpen();
  };

  self.toggle = function () {
    coreCollapsible.toggle();
  };

  self.open = function () {
    coreCollapsible.open();
  };

  self.close = function () {
    coreCollapsible.close();
  };

  self.reset = function () {
    initOpenSetting();
  };

  setupHandlers = function setupHandlers() {
    self.register('collapsible::isOpen');
    self.register('collapsible::toggle');
    self.register('collapsible::open');
    self.register('collapsible::close');
    self.register('collapsible::reset');
    self.subscribe('collapsible::isOpen collapsible::toggle collapsible::open collapsible::close collapsible::reset', handleEvent);
    self.register('collapsible::closeAllOther');
    self.subscribe('collapsible::closeAllOther', closeAllOther); // Reset to original incarnation on resize

    Kobo.$window.on('resize', onResize);
  };

  self.destroy = function () {
    self.unSubscribe('collapsible::isOpen', handleEvent);
    self.unSubscribe('collapsible::toggle', handleEvent);
    self.unSubscribe('collapsible::open', handleEvent);
    self.unSubscribe('collapsible::close', handleEvent);
    self.unSubscribe('collapsible::reset', handleEvent);
    self.unSubscribe('collapsible::closeAllOther', closeAllOther);
    Kobo.$window.off('resize', onResize);
  };

  init = function init() {
    if (!self.settings.name) {
      throw new Error('Collapsible requires a name!');
    }

    coreCollapsible = new Collapsible(self.$el);
    initOpenSetting();
    setupHandlers();

    Kobo._mediator.fire('collapsible::loaded');
  };

  init();
};

Kobo.Gizmo.Collapsible.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);