"use strict";

/*globals Kobo,ko,DynamicConfiguration */
Kobo.Gizmo.RichHeaderRgiSuperPointsBalance = function (el) {
  'use strict';

  Kobo.Gizmo.apply(this, arguments);
  this.setType("RichHeaderRgiSuperPointsBalance");
  this.viewModel = new Kobo.ViewModel.RichHeader.RgiSuperPointsBalanceVM();
  ko.applyBindings(this.viewModel, el);
  this.getRgiSuperPointsBalance();
};

Kobo.Gizmo.RichHeaderRgiSuperPointsBalance.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);

Kobo.Gizmo.RichHeaderRgiSuperPointsBalance.prototype.getRgiSuperPointsBalance = function () {
  var self = this;
  self.viewModel.availablePoints(DynamicConfiguration.resourceStrings.rgiPointsLoading);
  self.viewModel.standardPoints(DynamicConfiguration.resourceStrings.rgiPointsLoading);
  self.viewModel.limitedTermPoints(DynamicConfiguration.resourceStrings.rgiPointsLoading);
  Kobo.$.ajax({
    url: self.settings.getPointsBalanceUrl,
    method: 'GET',
    dataType: 'json',
    success: function success(data) {
      if (data) {
        if (data) {
          self.viewModel.update(data);
          self.viewModel.isHidden(false);
        }

        self.viewModel.isHidden(false);
      }
    },
    error: function error() {
      self.viewModel.isHidden(true);
    }
  });
};