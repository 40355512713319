"use strict";

/*globals Kobo,ko,DynamicConfiguration*/
(function () {
  'use strict';

  function AudiobookCreditBalanceVM() {
    this.creditBalance = ko.observable(0);
    this.isHidden = ko.observable(true);
    this.creditLabel = ko.pureComputed(function () {
      if (this.creditBalance() === 1) {
        return DynamicConfiguration.resourceStrings.credit_singular;
      } else {
        return DynamicConfiguration.resourceStrings.credit_plural;
      }
    }, this);
  }

  Kobo.Utilities.assignToNamespace('Kobo.ViewModel.RichHeader.AudiobookCreditBalanceVM', AudiobookCreditBalanceVM);
})();