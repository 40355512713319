"use strict";

/*global Kobo,$,console */
Kobo.Gizmo.SmallSecondaryRichHeader = function (gizmo, options) {
  "use strict";

  var self = this,
      currentScreenSize = Kobo._mediator.determineBreakpoint(),
      $sideMenuToggle,
      $sideMenu,
      $isSideMenuOpen,
      $sideMenuDocumentOverlay,
      $closeSideMenuButton,
      $headerSmallSearchToggleButton,
      $headerSmallSearchInputWidget,
      $headerSmallSearchField,
      $selectLanguageButton,
      $myAccountButton,
      init;

  Kobo.Gizmo.apply(this, arguments);
  this.setType("SmallSecondaryRichHeader");

  self.toggleSearchBar = function (shouldOpen) {
    if (shouldOpen === true) {
      Kobo._mediator.fire('collapsible::open', {
        name: 'searchBar'
      });
    } else if (shouldOpen === false) {
      Kobo._mediator.fire('collapsible::close', {
        name: 'searchBar'
      });
    } else {
      Kobo._mediator.fire('collapsible::toggle', {
        name: 'searchBar'
      });
    }

    self.isSearchBarOpen(function (isOpen) {
      self.updatePageState(isOpen);
      self.setFocusSearchBar(isOpen);
    });
  };

  self.isSearchBarOpen = function (callback) {
    Kobo._mediator.fire('collapsible::isOpen', {
      name: 'searchBar',
      callback: callback
    });
  }; // if search is open, then close the shopping cart panel, and hook up to the "hide when scrolling" event.
  // is search is closed, remove styling on the search icon and stop listening to the scrolling event.


  self.updatePageState = function (isOpen) {
    if (isOpen) {
      $headerSmallSearchToggleButton.addClass(options.headerSearchOpenSelector);

      if (currentScreenSize !== 'small') {
        self.fire('richHeader::hideShoppingCart'); // allow some time for slower devices to scroll to the top before attaching the hide-on-scroll behavior

        setTimeout(function () {
          self.toggleHideSearchOnScrollBehaviour(true);
        }, 1000);
      }
    } else {
      $headerSmallSearchToggleButton.removeClass(options.headerSearchOpenSelector);

      if (currentScreenSize !== 'small') {
        self.toggleHideSearchOnScrollBehaviour(false);
      }
    }
  }; // toggle whether to hook up to the "hide search bar when scrolling" or not


  self.toggleHideSearchOnScrollBehaviour = function (hookToEvent) {
    if (hookToEvent) {
      Kobo.$window.on('scroll', self._hideSearchWhenScrolledOutOfView);
    } else {
      Kobo.$window.off('scroll', self._hideSearchWhenScrolledOutOfView);
    }
  }; // AB 2019.05.22: Bahaviour changed to make search sticky on small.
  // On small view, search is always open. Shopping cart is closed initially,
  // click to toggle
  // On medium view, default is closed (for all), click to toggle.
  // On large and jumbo views, hide the smallsearch bar


  self.setDefaultSearchBarToggle = function (screenSize) {
    screenSize = screenSize || Kobo._mediator.determineBreakpoint();

    switch (screenSize) {
      case 'small':
        self.toggleSearchBar(true);
        break;

      default:
        self.toggleSearchBar(false);
        break;
    }
  };

  self._hideSearch = function () {
    self.toggleSearchBar(false);
  };

  self._hideSearchWhenScrolledOutOfView = Kobo.Utilities.Events.debounce(function () {
    var isVisible = Kobo.Utilities.DomUtils.isScrolledIntoView($headerSmallSearchInputWidget);

    if (!isVisible) {
      self._hideSearch();

      self.toggleHideSearchOnScrollBehaviour(false);
    }
  }, 50);

  self.setFocusSearchBar = function (isOpen) {
    if (isOpen && currentScreenSize !== 'small') {
      setTimeout(function () {
        $headerSmallSearchField.focus();
      }, 250);
    }
  };

  self.closeSmallSideMenu = function (e, data) {
    var targetElement = data && data.detail && data.detail.targetElement ? data.detail.targetElement : null;
    var clickedOutsideOfSideMenu = false;

    if (targetElement) {
      clickedOutsideOfSideMenu = Kobo.$(targetElement).closest($sideMenu).length === 0;
    }

    if (targetElement && !clickedOutsideOfSideMenu) {
      return;
    } // close side menu


    if ($isSideMenuOpen) {
      $sideMenuDocumentOverlay.classList.remove("side-menu-show-document-overlay");
      $closeSideMenuButton.parentElement.style.display = "none";
      $isSideMenuOpen = false;
      self.$gizmo.removeClass(options.slideSmallMainMenuRightSelector).addClass(options.slideSmallMainMenuLeftSelector);
      $sideMenu.removeClass(options.showSideMenuSelector).addClass(options.hideSideMenuSelector);
      $sideMenuToggle.removeClass(options.sideMenuIsOpenSelector).addClass(options.sideMenuIsClosedSelector);
      $sideMenuToggle.attr('aria-expanded', false); // slide main body back to full screen

      Kobo.$body.removeClass(options.pushContentRightSelector).addClass(options.pushContentLeftSelector);
      $headerSmallSearchInputWidget.removeClass(options.pushSearchBarRightSelector).addClass(options.pushSearchBarLeftSelector);
    }
  };

  self.toggleSmallSideMenu = function (e) {
    if (self.$gizmo.hasClass(options.slideSmallMainMenuLeftSelector)) {
      // open side menu
      $isSideMenuOpen = true;

      if ($closeSideMenuButton === null) {
        $closeSideMenuButton = document.querySelector(".close-side-menu-button");
        $closeSideMenuButton.addEventListener('click', function (e) {
          e.preventDefault();
          self.closeSmallSideMenu();
        });
      }

      $closeSideMenuButton.parentElement.style.display = "block";

      if ($sideMenuDocumentOverlay === null) {
        $sideMenuDocumentOverlay = document.querySelector(".side-menu-document-overlay");
      }

      $sideMenuDocumentOverlay.classList.add("side-menu-show-document-overlay");
      self.$gizmo.removeClass(options.slideSmallMainMenuLeftSelector).addClass(options.slideSmallMainMenuRightSelector);
      $sideMenu.removeClass(options.hideSideMenuSelector).addClass(options.showSideMenuSelector);
      $sideMenuToggle.removeClass(options.sideMenuIsClosedSelector).addClass(options.sideMenuIsOpenSelector);
      $sideMenuToggle.attr('aria-expanded', true); // push main body of the page to make room for the side menu

      Kobo.$body.removeClass(options.pushContentLeftSelector).addClass(options.pushContentRightSelector);
      $headerSmallSearchInputWidget.removeClass(options.pushSearchBarLeftSelector).addClass(options.pushSearchBarRightSelector);

      if (e.type === 'keydown') {
        if ($selectLanguageButton.length > 0) {
          $selectLanguageButton.trigger('focus');
        } else {
          $myAccountButton.trigger('focus');
        }
      }
    } else if ($isSideMenuOpen) {
      self.closeSmallSideMenu(e);
    }
  }; // Wait until Gizmo.Collapsible finishes loading to set up the search bar.
  // Do this only once.


  self.setupSearchBar = function () {
    self.setDefaultSearchBarToggle();
    self.unSubscribe('collapsible::loaded', self.setupSearchBar);
  };

  self.destroy = function () {
    self.unSubscribe('richHeader::hideSmallSearch', self._hideSearch);
    self.unSubscribe('shoppingCart::opening', self._onShoppingCartOpened);
    self.unSubscribe('shoppingCart::closing', self._onShoppingCartClosed);
    self.unSubscribe('smallSideMenu::toggle', self.toggleSmallSideMenu);
    self.unSubscribe('smallSideMenu::close', self.closeSmallSideMenu);
    self.toggleHideSearchOnScrollBehaviour(false);
    self._hideSearchWhenScrolledOutOfView = null;
  };

  self.setupHandlers = function () {
    self.register('richHeader::hideSmallSearch');
    self.subscribe('richHeader::hideSmallSearch', self._hideSearch);
    self.register('collapsible::loaded');
    self.subscribe('collapsible::loaded', self.setupSearchBar);
    self.register('smallSideMenu::toggle');
    self.subscribe('smallSideMenu::toggle', self.toggleSmallSideMenu);
    self.register('smallSideMenu::close');
    self.subscribe('smallSideMenu::close', self.closeSmallSideMenu);
    $sideMenuToggle.on('click keydown', function (e) {
      if (e.type === 'click' || e.type === 'keydown' && (e.keyCode === Kobo.KeyCodes.SPACE || e.keyCode === Kobo.KeyCodes.RETURN)) {
        e.preventDefault();
        e.stopPropagation();
        self.toggleSmallSideMenu(e);
      }
    }); // toggle search drawer on click

    $headerSmallSearchToggleButton.on('click', function (e) {
      e.preventDefault();
      self.toggleSearchBar();
    });
    self.events.onResize(function () {
      var screenSize = Kobo._mediator.determineBreakpoint();

      self.toggleSearchInputClass(screenSize);

      if (currentScreenSize !== screenSize) {
        currentScreenSize = screenSize;
        self.setDefaultSearchBarToggle(currentScreenSize);
      }
    });
  };

  self.toggleSearchInputClass = function (screenSize) {
    var currentScreenSize = screenSize || Kobo._mediator.determineBreakpoint();

    if (currentScreenSize === 'small') {
      $headerSmallSearchInputWidget.addClass('SearchInputWidgetSmall');
    } else {
      $headerSmallSearchInputWidget.removeClass('SearchInputWidgetSmall');
    }
  };

  init = function init() {
    $sideMenu = Kobo.$('.' + options.sideMenuSelector);
    $sideMenuToggle = self.$gizmo.find('.' + options.sideMenuToggleSelector);
    $headerSmallSearchToggleButton = self.$gizmo.find('.' + options.headerSearchBarToggleSelector); // "headerSearchBarToggleSelector":"rich-header-small-search",

    $headerSmallSearchInputWidget = Kobo.$('.' + options.headerSearchInputWidgetSelector); // "headerSearchInputWidgetSelector": "kobo-main .SearchInputWidget",

    $headerSmallSearchField = Kobo.$('.' + options.headerSearchFieldSelector); // "headerSearchFieldSelector":"search-field"}

    $selectLanguageButton = Kobo.$('.language-selector');
    $myAccountButton = Kobo.$('button.profile-wrapper');
    $isSideMenuOpen = false;
    $closeSideMenuButton = null;
    $sideMenuDocumentOverlay = null;
    self.setupHandlers();
    self.$el.toggleClass('is-not-mobile-device', !window.DynamicConfiguration.isMobileDevice);
    self.toggleSearchInputClass();
  };

  init();
};

Kobo.Gizmo.SmallSecondaryRichHeader.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);