"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

/*global Kobo,$*/
if (_typeof(Kobo.Ajax) !== "object") {
  /**
   * Kobo.Ajax - wrapper for constructing AJAX calls. Currently wrapping the jQuery .ajax() method.
   * @param args
   * @constructor
   */
  Kobo.Ajax = function (args) {
    "use strict";

    return Kobo.$.ajax(args);
  };
}