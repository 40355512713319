"use strict";

/*global Kobo,$,console */
Kobo.Gizmo.HeaderCategories = function (el, options) {
  'use strict';

  var self = this;
  Kobo.Gizmo.apply(this, arguments); // inherit from base class

  this.setType('HeaderCategories');

  self.fitListToPanel = function () {
    var screenMode = Kobo._mediator.determineBreakpoint(),
        ulItems = Kobo.$('.' + self.ulSelectorClass + ' > li');

    if (screenMode === "small" || screenMode === "medium") {
      self.showHideForSmallView(ulItems, parseInt(self.smallViewMaxDisplay, 10));
    } else {
      ulItems.show();
    }
  };

  self.showHideForSmallView = function (ulItems, maxItems) {
    var j;

    for (j = 0; j < ulItems.length; j++) {
      if (j < maxItems) {
        Kobo.$(ulItems[j]).show();
      } else {
        Kobo.$(ulItems[j]).hide();
      }
    }
  };

  self._onWindowResized = function () {
    self.fitListToPanel();
  };

  self.setupHandlers = function () {
    self.events.onResize(self._onWindowResized);
  };

  self.init = function () {
    self.ulSelectorClass = options.ulSelectorClass;
    self.wrapperDivClass = options.wrapperDivClass;
    self.smallViewMaxDisplay = options.smallViewMaxDisplay;
    self.wrapperId = options.wrapperId;
    self.fitListToPanel();
    self.setupHandlers();
  };

  self.destroy = function () {
    self.events.offResize(self._onWindowResized);
  };

  self.init();
};

Kobo.Gizmo.HeaderCategories.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);