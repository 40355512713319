"use strict";

/**
 * Collapsible
 * @description Creates a collapsible region that can animate to open and closed states
 * @param {element} element
 */
function Collapsible($element) {
  'use strict';

  this.$element = $element;
}

Collapsible.prototype = {
  isOpen: function isOpen() {
    'use strict';

    return this.$element.hasClass('open');
  },
  toggle: function toggle() {
    'use strict';

    if (this.isOpen()) {
      this.close();
    } else {
      this.open();
    }
  },
  open: function open() {
    'use strict';

    this.$element.addClass('open');
  },
  close: function close() {
    'use strict';

    this.$element.removeClass('open');
  }
};