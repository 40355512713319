"use strict";

/*global Kobo,$,ko,Modernizr*/
(function (Kobo) {
  /**
   * Kobo.Gizmo.Expandable
   * @description Creates an expandable region that can open/close using .toggle() method on the view model
   * @constructor
   */
  function GizmoExpandable() {
    "use strict";
    /* The default settings of the gizmo */

    this.setDefaults({
      isCollapsed: false,

      /* can also be an object in the form of isCollapsed: { "small": true, "medium": false, etc. } */
      height: "0px",
      duration: "0.5s",
      initiallyHiddenClasses: ["gizmo-expandable-collapsed-on-load", "gizmo-expandable-collapsed-on-load-mobile"],
      allowBindings: true,
      collapseOnEvent: false,

      /* string that holds the event name */
      toggleEvent: false,
      expandableContentClassName: '',
      extendToListItemWidth: false,
      ariaLabel: '',
      heightBinding: false
    });
    /* Call super */

    Kobo.Gizmo.apply(this, arguments);
    this.setType("Expandable"); // Content to wrap with expandable helper structure is either gizmo.contents()
    // or anything that have expandableContentClassName

    if (this.settings.expandableContentClassName && this.$gizmo.find('.' + this.settings.expandableContentClassName).length > 0) {
      this.contents = this.$gizmo.find('.' + this.settings.expandableContentClassName);
    } else {
      this.contents = this.$gizmo.contents();
    }

    this.contents.wrapAll('<div class="gizmo-expandable-inner-content" data-bind="allowBindings: ' + this.settings.allowBindings.toString() + '"></div>');
    this.$innerContent = this.$gizmo.find('.gizmo-expandable-inner-content:first');
    this.isExpandable = false;
    this.$innerMasker = null;
    this.animationComplete = false;
    this.viewModel = null;
    this.currentScreenSize = Kobo._mediator.determineBreakpoint(); // Bind callbacks

    this.applyTransitions = this.applyTransitions.bind(this);
    this.setupHandlers();
    this.toggleExpandable();
    this.clearInitiallyHiddenStyle();
  }

  GizmoExpandable.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);

  GizmoExpandable.prototype.clearInitiallyHiddenStyle = function () {
    "use strict";

    for (var i = 0; i < this.settings.initiallyHiddenClasses.length; i++) {
      this.$el.removeClass(this.settings.initiallyHiddenClasses[i]);
    }
  };

  GizmoExpandable.prototype.defaultHeight = function () {
    return this.$innerContent.outerHeight();
  };

  GizmoExpandable.prototype.shouldConsiderHeightBinding = function () {
    return this.settings.heightBinding;
  };

  GizmoExpandable.prototype.checkIfScreenSizeIsLarge = function () {
    return this.currentScreenSize !== "small" && this.currentScreenSize !== "medium";
  };

  GizmoExpandable.prototype.updateDefaultHeight = function () {
    var h = this.defaultHeight();
    this.$innerMasker.css({
      "height": h + "px"
    });
  };

  GizmoExpandable.prototype.buildExpandable = function () {
    if (!this.isExpandable) {
      this.wrapContents();

      if (this.settings.viewModel !== undefined && Kobo.ViewModel.Expandable[this.settings.viewModel]) {
        this.viewModel = new Kobo.ViewModel.Expandable[this.settings.viewModel](this);
        if (!ko.dataFor(this.el)) ko.applyBindings(this.viewModel, this.el);
      } else {
        this.viewModel = new Kobo.ViewModel.Expandable(this);
        if (!ko.dataFor(this.el)) ko.applyBindings(this.viewModel, this.el);
      }
    }

    this.setupAccessibilityHandlers();
    this.isExpandable = true;
  };

  GizmoExpandable.prototype.tearDownExpandable = function () {
    ko.cleanNode(this.el);
    this.$innerMasker.replaceWith(this.$innerContent);
    this.isExpandable = false;
    this.fire('expandable::removeButton');
  };

  GizmoExpandable.prototype.toggleExpandable = function () {
    if (this.needsExpanding() && !this.isExpandable) {
      this.buildExpandable();
      setTimeout(this.applyTransitions, 500);
    } else if (!this.needsExpanding() && this.isExpandable) {
      this.tearDownExpandable();
    }
  };

  GizmoExpandable.prototype.setupHandlers = function () {
    var _this = this;

    this.events.onResize(function () {
      _this.toggleExpandable();
    });
  };

  GizmoExpandable.prototype.setupAccessibilityHandlers = function () {
    var _this2 = this;

    var contentLinks = this.viewModel.$contentLinks;
    contentLinks.on('keydown', function (e) {
      if (e.keyCode === Kobo.KeyCodes.TAB && !_this2.viewModel.allowTab && !_this2.viewModel.isCollapsed()) {
        _this2.viewModel.onButtonSelected();

        _this2.viewModel.$button.focus();
      } else if (e.keyCode === Kobo.KeyCodes.ESC && !_this2.viewModel.isCollapsed()) {
        _this2.viewModel.onButtonSelected();

        _this2.viewModel.$button.focus();
      } else if (e.keyCode === Kobo.KeyCodes.DOWN) {
        e.preventDefault();
        var currentElement = $(e.target).parent();
        var nextElement = currentElement.next();

        if (nextElement.length > 0) {
          nextElement.children().focus();
        } else {
          currentElement.siblings().first().children().focus();
        }
      } else if (e.keyCode === Kobo.KeyCodes.UP) {
        e.preventDefault();

        var _currentElement = $(e.target).parent();

        var prevElement = _currentElement.prev();

        if (prevElement.length > 0) {
          prevElement.children().focus();
        } else {
          _currentElement.siblings().last().children().focus();
        }
      }
    });
  };

  GizmoExpandable.prototype.wrapContents = function () {
    if (this.shouldConsiderHeightBinding() && this.checkIfScreenSizeIsLarge()) {
      this.$innerContent.wrapAll('<div class="gizmo-expandable-inner-masker"></div>');
    } else {
      this.$innerContent.wrapAll('<div class="gizmo-expandable-inner-masker" data-bind="style: { height: height }"></div>');
    }

    this.$innerMasker = this.$gizmo.find('.gizmo-expandable-inner-masker:first');
  };

  GizmoExpandable.prototype.applyTransitions = function () {
    if (Modernizr.csstransitions) {
      this.$innerMasker.css(Modernizr.prefixed('transition', this.$innerMasker.prop('style'), false), "height " + this.settings.duration);
    }
  };

  GizmoExpandable.prototype.needsExpanding = function () {
    /* if quickview is enabled */
    return parseInt(this.defaultHeight(), 10) > parseInt(this.settings.height, 10);
  };
  /**
   * Static methods
   */

  /**
   * Returns array of expandable gizmos from descendants of the given gizmo
   * @param {Kobo.Gizmo} gizmo
   * @return {Array | false} array or expandable gizmos, or FALSE if given parameter is not a gizmo.
   */


  GizmoExpandable.getExpandableDescendants = function (gizmo) {
    "use strict";

    if (gizmo && typeof gizmo.type === 'string' && gizmo.type.indexOf("Class.Gizmo") !== 0) {
      return false;
    }

    if (gizmo.descendants.length > 0) {
      return Kobo.Gizmo.Expandable.getExpandables(gizmo.descendants);
    }

    return [];
  };
  /**
   * Searches for expandable gizmos in given array gizmos
   * @param {array} gizmoArr
   * @return {array}
   */


  GizmoExpandable.getExpandables = function (gizmoArr) {
    "use strict";

    var expandableGizmos = [],
        i,
        gizmo;

    for (i = 0; i < gizmoArr.length; i++) {
      gizmo = gizmoArr[i];

      if (gizmo.type.indexOf("Class.Gizmo.Expandable") === 0) {
        expandableGizmos.push(gizmo);
      }
    }

    return expandableGizmos;
  }; // Exports


  Kobo.Gizmo.Expandable = GizmoExpandable;
})(Kobo);