"use strict";

/*global Kobo,$,console */
Kobo.Gizmo.RichHeaderAccountOptions = function (gizmo, options) {
  'use strict';

  Kobo.Gizmo.apply(this, arguments);
  this.setType('RichHeaderAccountOptions');
  var self = this,
      $accountDropDownMenu = self.$gizmo.find('.' + options.accountDropDownMenuSelector),
      $accountDropDownButtonSelector = self.$gizmo.find('.' + options.accountDropDownButtonSelector),
      $accountDropDownMenuButton = self.$gizmo,
      $accountDropDownShowMenu = options.accountDropDownShowMenuSelector,
      $accountDropDownCollapse = options.accountDropDownCollapseSelector,
      $accountDropDownExpand = options.accountDropDownExpandSelector,
      focusInByClick = false,
      sideMenuShowing = window.matchMedia('all and (max-width: 880px)');

  self.collapseAccountDropdown = function () {
    $accountDropDownMenu.removeClass($accountDropDownShowMenu);
    $accountDropDownMenuButton.addClass($accountDropDownCollapse).removeClass($accountDropDownExpand);
    $accountDropDownButtonSelector.attr('aria-expanded', false);
  };

  self.expandAccountDropdown = function () {
    $accountDropDownMenu.addClass($accountDropDownShowMenu);
    $accountDropDownMenuButton.removeClass($accountDropDownCollapse).addClass($accountDropDownExpand);
    $accountDropDownButtonSelector.attr('aria-expanded', true);
  };

  self.isTouchScreen = function () {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  };

  self.setupHandlers = function () {
    $accountDropDownMenuButton.on('mouseenter', function () {
      if (!sideMenuShowing.matches) {
        if ($accountDropDownMenuButton.hasClass($accountDropDownCollapse)) {
          self.expandAccountDropdown();
        }
      }
    });
    $accountDropDownMenuButton.on('mouseleave', function () {
      if (!sideMenuShowing.matches) {
        if ($accountDropDownMenuButton.hasClass($accountDropDownExpand)) {
          self.collapseAccountDropdown();
        }
      }
    });
    $accountDropDownButtonSelector.on('mousedown', function (event) {
      focusInByClick = true;
    });
    $accountDropDownMenuButton.on('click', function (event) {
      if ($accountDropDownMenuButton.hasClass($accountDropDownExpand)) {
        self.collapseAccountDropdown();
      } else {
        self.expandAccountDropdown();
      }

      if (!self.isTouchScreen() && !sideMenuShowing.matches) {
        document.activeElement.blur();
      }
    });
    $accountDropDownMenuButton.on('focusin', function (event) {
      if ($accountDropDownMenuButton.hasClass($accountDropDownCollapse) && !focusInByClick) {
        event.preventDefault();
        self.expandAccountDropdown();
      }
    });
    $accountDropDownMenuButton.on('focusout', function (event) {
      // Collapse the menu if clicking outside of it
      if (!focusInByClick && event.relatedTarget === null && $accountDropDownMenuButton.hasClass($accountDropDownExpand)) {
        self.collapseAccountDropdown();
      }

      focusInByClick = false;
    });
    $accountDropDownMenuButton.on('keydown', function (event) {
      if ($accountDropDownMenuButton.hasClass($accountDropDownExpand)) {
        // Collapse the menu when tabbing out
        if (event.keyCode === Kobo.KeyCodes.TAB) {
          if (event.shiftKey && event.target === $('button.profile-wrapper')[0] || !event.shiftKey && event.target === $('li.sign-out a')[0]) {
            self.collapseAccountDropdown();
            $accountDropDownMenu.blur();
          }
        } // Collapse the menu when pressing Escape
        else if (event.keyCode === Kobo.KeyCodes.ESC) {
          self.collapseAccountDropdown();
          $accountDropDownMenu.blur();
        }
      } else {
        // Open the menu if pressing RETURN or SPACE while it's focused
        if (event.keyCode === Kobo.KeyCodes.RETURN || event.keyCode === Kobo.KeyCodes.SPACE) {
          event.preventDefault();
          $accountDropDownMenu.focus();
          self.expandAccountDropdown();
        }
      }
    });
  };

  self.init = function () {
    self.setupHandlers();
    var targetContainer = document.querySelector('.rich-header');
    Kobo.event.setRippleEffectEvent(targetContainer, 'ripple');
  };

  self.init();
};

Kobo.Gizmo.RichHeaderAccountOptions.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);