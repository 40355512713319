"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/*global Kobo*/
(function (Kobo) {
  /**
   * Kobo.Gizmo - Class all base gizmos MUST inherit from
   * @param el
   * @param options
   * @constructor
   */
  function Gizmo(el, options) {
    "use strict";

    Kobo.Class.call(this);
    this.options = options || {};
    this.defaults = this.defaults || {};
    this.settings = _objectSpread(_objectSpread({}, this.defaults), this.options);
    this.setType("Gizmo");
    this.ancestors = [];
    this.descendants = [];
    this.guid = this.generateID();
    this.utility = Kobo || {}; // Only do the following if a gizmo is passed in

    if (el) {
      this.el = el;
      this.$el = Kobo.$(this.el);
      this.gizmo = this.el;
      this.$gizmo = this.$el; // REQUIRED - Flag so that initialized gizmos can't be re-initialized

      this.el.initialized = true;
      this.$el.data('instance', this);
      this.$el.attr('data-gid', this.guid);
    }
  }

  Gizmo.prototype = Kobo.chainPrototype(Kobo.Class.prototype);

  Gizmo.prototype.generateID = function () {
    "use strict";

    return "g_" + Kobo._gizmo.gizmoList.total;
  };

  Kobo.Gizmo = Gizmo;
})(Kobo);