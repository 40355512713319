"use strict";

/*global Kobo,$,console */
Kobo.Gizmo.MyBooksFilters = function () {
  "use strict";

  Kobo.Gizmo.apply(this, arguments);
  this.setType("MyBooksFilters");
  this.defaults = {
    "filterChip": null,
    "filterButton": null,
    "filterList": null,
    "filterItem": null
  };
  var self = this;

  function toggleFilterChip($btn) {
    var closeList = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var $chip = $btn.closest('.' + self.settings.filterChip);
    var $list = $($chip.find('.' + self.settings.filterList));

    if ($list.hasClass('open') || closeList) {
      $btn.removeClass('selected');
      $list.removeClass('open');
      $btn.attr("aria-expanded", "false");
      $list.attr("aria-hidden", "true");
    } else {
      $btn.addClass('selected');
      $list.addClass('open');
      $btn.attr("aria-expanded", "true");
      $list.attr("aria-hidden", "false");
    }
  }

  function setUpHandlers() {
    var $btn = $('.' + self.settings.filterButton);
    var $list = $('.' + self.settings.filterList);
    $btn.on('click', function () {
      toggleFilterChip($(this));
    });
    $btn.on('focusout', function (event) {
      if (event.relatedTarget === null || !$(event.relatedTarget).hasClass(self.settings.filterItem)) {
        toggleFilterChip($(this), true);
      }
    });
    $btn.on('keydown', function (event) {
      var $btn = $(this);

      if (event.keyCode === Kobo.KeyCodes.ESC) {
        toggleFilterChip($btn, true);
      }
    });
    $list.on('focusout keydown', function (event) {
      var $btn = $(this).prevAll('.' + self.settings.filterButton).first();
      $btn.trigger(event);
    });
  }

  function init() {
    setUpHandlers();
  }

  init();
};

Kobo.Gizmo.MyBooksFilters.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);