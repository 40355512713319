"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/*global Kobo,ko,$,Modernizr,DynamicConfiguration*/
(function (Kobo) {
  /**
   * Kobo.ViewModel.Expandable
   * @desc This is the View Model for building a basic Expandable
   * @param {element} gizmo
   * @augments Kobo.ViewModel
   * @constructor
   */
  function ViewModelExpandable(gizmo) {
    "use strict";

    this.gizmo = gizmo;
    gizmo.viewModel = this;
    this.settings = gizmo.settings;
    this.$el = gizmo.$gizmo;
    this.$expandableHeader = gizmo.$innerContent.find('> .expandable-header');
    this.isCollapsed = this.createIsCollapsedObservable();
    this.$contentLinks = Kobo.Utilities.findAllFocusableElements(gizmo.$innerContent);
    var transEndEventNames = {
      'WebkitTransition': 'webkitTransitionEnd',
      'MozTransition': 'transitionend',
      'OTransition': 'oTransitionEnd',
      'msTransition': 'MSTransitionEnd',
      'transition': 'transitionend'
    };
    this.transEndEventName = transEndEventNames[Modernizr.prefixed('transition')];
    this.height = ko.observable(this.getGizmoHeight());

    this.animationQueueEnd = function () {}; // bind event handlers


    this.recalculateHeight = this.recalculateHeight.bind(this);
    this.setupHandlers();

    if (this.$expandableHeader.length > 0) {
      this.$el.prepend(this.$expandableHeader);

      if (this.settings.allowBindings === "false") {
        this.$expandableHeader.wrapAll('<div data-bind="allowBindings: ' + this.settings.allowBindings.toString() + '"/>');
      }
    }
  }

  ViewModelExpandable.prototype = {};

  ViewModelExpandable.prototype.recalculateHeight = function () {
    this.height(this.getGizmoHeight());
  };

  ViewModelExpandable.prototype.getGizmoHeight = function () {
    if (this.isCollapsed()) {
      return this.gizmo.settings.height;
    } else {
      return this.gizmo.defaultHeight() + 'px';
    }
  };

  ViewModelExpandable.prototype.createIsCollapsedObservable = function () {
    var observable;

    if (Kobo.typeOf(this.gizmo.settings.isCollapsed) === "object") {
      var isCollapsedAtBreakpoint = Kobo.Object.parseBool(this.gizmo.settings.isCollapsed[this.gizmo.currentBreakpoint()]);
      observable = ko.observable(isCollapsedAtBreakpoint);
    } else {
      observable = ko.observable(this.gizmo.settings.isCollapsed);
    }

    return observable;
  };

  ViewModelExpandable.prototype.animate = function (newHeight) {
    this.gizmo.$innerMasker.stop().animate({
      height: newHeight
    }, this.animationQueueEnd);
  };
  /**
   * self.toggle
   * @desc toggles the
   * @param height
   */


  ViewModelExpandable.prototype.toggle = function (height) {
    var _this = this;

    if (Kobo.Object.typeOf(height) === "boolean") {
      this.isCollapsed(!height);
      height = this.getGizmoHeight();
    } else {
      if (isNaN(parseFloat(height))) {
        height = undefined;
      }

      this.isCollapsed(!this.isCollapsed());
    }

    var newHeight = height !== undefined ? height : this.getGizmoHeight();

    if (Modernizr.csstransitions) {
      this.height(newHeight);
    } else {
      this.animate(newHeight);
    }

    if (this.settings.toggleEvent !== false) {
      this.gizmo.fire(this.settings.toggleEvent, {
        isCollapsed: this.isCollapsed()
      });
    }

    if (this.settings.collapseOnEvent !== false) {
      var currentBreakpoint = this.gizmo.currentBreakpoint();

      if (currentBreakpoint === "small" || currentBreakpoint === "medium") {
        this.gizmo.fire(this.settings.collapseOnEvent, {
          gizmo: this.gizmo
        });
      }
    } // There is a defect with stock Android device browsers, where the transitionend event detected by Modernizr is the non-prefixed version. This section queues an expandableResized event fire, which is cleared if the self.transEndEventName actually gets triggered.
    // See  http://nebse.com/modernizr-returns-incorrect-transition-for-native-android-browser/ for more


    this.stockAndroidTimeout = setTimeout(function () {
      _this.gizmo.fire("expandableResized", {
        "height": _this.height()
      });
    }, 800);
  };
  /**
   * updates the aria attributes that indicate the accessibility status
   */


  ViewModelExpandable.prototype.updateAria = function (button) {
    var ariaValue = button.attr('aria-expanded') === 'true';

    if (ariaValue !== undefined) {
      button.attr('aria-expanded', !ariaValue);
    }
  };
  /**
   * To be used for click binding
   * @param {onclick event} e
   */


  ViewModelExpandable.prototype.toggleAction = function (e) {
    this.toggle();
  };

  ViewModelExpandable.prototype.registerExpandableResizedEvent = function () {
    var _this2 = this;

    this.gizmo.register("expandableResized");

    if (Modernizr.csstransitions) {
      this.gizmo.$innerMasker.on(this.transEndEventName, function () {
        clearTimeout(_this2.stockAndroidTimeout);

        _this2.gizmo.fire("expandableResized", {
          "height": _this2.height()
        });
      });
    } else {
      this.animationQueueEnd = function () {
        _this2.gizmo.fire("expandableResized", {
          "height": _this2.height()
        });
      };
    }
  };

  ViewModelExpandable.prototype.setupHandlers = function () {
    this.gizmo.events.onResize(this.recalculateHeight);
  };
  /**
   * Kobo.ViewModel.Expandable.BaseButton
   * @desc This is the Base button expandable view model
   * @param {element} gizmo
   * @param {object} [options]
   * @constructor
   */


  function ViewModelExpandableBaseButton(gizmo, options) {
    "use strict";

    Kobo.ViewModel.Expandable.apply(this, arguments);
    var self = this,
        init;
    self.$button = null;
    self.buttonWidth = null;

    self.registerExpandableRemoveButton = function () {
      gizmo.register('expandable::removeButton');
      gizmo.subscribe('expandable::removeButton', function () {
        self.removeButton();
      });
    };

    self.onButtonSelected = function () {
      if (self.isCollapsed()) {
        self.$button.find('.gizmo-expandable-subbutton').css('-webkit-animation', 'rotation-down 0.5s 1 linear').css('transform', ''); // Transform keeps arrow in rotated position

        self.$contentLinks.attr('tabindex', '0').attr('aria-hidden', '');
      } else {
        self.$button.find('.gizmo-expandable-subbutton').css('-webkit-animation', 'rotation-up 0.5s 1 linear').css('transform', 'none');
        self.$contentLinks.attr('tabindex', '-1').attr('aria-hidden', 'true'); // hiding from screen reader when list is not expanded
      }

      self.toggle();
      self.updateAria(self.$button);
    };

    self.handleButtonKeyDown = function (e) {
      switch (e.keyCode) {
        case Kobo.KeyCodes.ESC:
          if (!self.isCollapsed()) {
            self.onButtonSelected();
            self.$button.focus();
          }

          break;

        case Kobo.KeyCodes.SPACE:
          e.preventDefault();

        case Kobo.KeyCodes.DOWN:
        case Kobo.KeyCodes.RETURN:
          self.onButtonSelected();

          if (self.$contentLinks && self.$contentLinks.length !== 0) {
            self.$contentLinks[0].focus();
          }

          break;

        case Kobo.KeyCodes.UP:
          self.onButtonSelected();

          if (self.$contentLinks && self.$contentLinks !== 0) {
            self.$contentLinks[self.$contentLinks.length - 1].focus();
          }

          break;

        default:
          break;
      }
    };

    gizmo.$gizmo.addClass("gizmo-expandable");
    self.addButton();
    self.$button.on('click', self.onButtonSelected);
    self.$button.on('keydown', self.handleButtonKeyDown);
    self.registerExpandableRemoveButton();
  }

  ViewModelExpandableBaseButton.prototype = Kobo.chainPrototype(ViewModelExpandable.prototype);

  ViewModelExpandableBaseButton.prototype.addButton = function () {
    "use strict";

    if (!this.gizmo.settings.isButtonRenderedOnServer) {
      var buttonId = "expandable-button-".concat(this.gizmo.guid);
      var contentListId = "expandable-list-".concat(this.gizmo.guid);

      var optionalButtonProps = _objectSpread(_objectSpread({}, this.gizmo.settings.ariaLabel ? {
        'aria-label': DynamicConfiguration.resourceStrings.filterSortProducts
      } : {}), this.gizmo.settings.ariaHasPopup ? {
        'aria-haspopup': true
      } : {});

      this.$button = Kobo.$("<button>", _objectSpread({
        'id': buttonId,
        'class': 'gizmo-expandable-button',
        'type': 'button',
        'aria-controls': contentListId
      }, optionalButtonProps));
      this.$button.text(this.gizmo.settings.text);
      this.$el.find('ul').attr('id', contentListId).attr('aria-labelledby', buttonId);
      this.$el.append(this.$button);
    } else {
      this.$button = this.$el.find("button#" + this.gizmo.settings.expandableButtonId);
    }

    this.$button.data('bind', 'css: {"gizmo-expandable-button-collapsed": isCollapsed()}');
    this.$button.attr('aria-expanded', !this.isCollapsed());
    this.buttonWidth = this.$button.width() / 2;
    this.$el.find('ul').attr('aria-busy', false);
    this.$el.data('data-bind', 'css: {"gizmo-expandable-collapsed": isCollapsed()}');
  };

  ViewModelExpandableBaseButton.prototype.removeButton = function () {
    "use strict";

    this.$el.find('.gizmo-expandable-button').remove();
  }; // Exports


  if (Kobo.ViewModel === undefined) {
    Kobo.ViewModel = {};
  }

  Kobo.ViewModel.Expandable = ViewModelExpandable;
  Kobo.ViewModel.Expandable.BaseButton = ViewModelExpandableBaseButton;
})(Kobo);