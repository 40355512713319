"use strict";

/*global Kobo,ko,$,console,stringXmlToJson*/
if (!Kobo.ViewModel) {
  Kobo.ViewModel = {};
  ko.bindingHandlers.allowBindings = {
    init: function init(elem, valueAccessor) {
      "use strict"; // Let bindings proceed as normal *only if* my value is false

      var shouldAllowBindings = ko.utils.unwrapObservable(valueAccessor());
      return {
        controlsDescendantBindings: !shouldAllowBindings
      };
    }
  };
}

Kobo.ViewModel.Base = function (element, options) {
  "use strict";

  Kobo.Class.call(this);
  var self = this;
  self.setType("ViewModelBase");
  this.options = options || {};
  this.defaults = Kobo.extend({
    dataPageSize: 8,
    dataPageBaseNumber: 1,
    maxDataItemsForTouch: 15
  }, self.defaults);
  this.settings = Kobo.extend({}, this.defaults, this.options);
  this.el = element;
  this.$el = Kobo.$(element); // defaults

  this.fetchQuery = "";
  this.fetchPageSizeOffline = this.settings.totalNumberOfItems ? this.settings.totalNumberOfItems : 10;
  this.isOffline = this.settings.isOffline ? true : false;
  this.offlineData = eval(this.settings.offlineDataSource);
};

Kobo.ViewModel.Base.prototype = Kobo.chainPrototype(Kobo.Class.prototype);

Kobo.ViewModel.Base.prototype.convertListToTemplate = function () {
  "use strict"; // remove all but first list items

  this.$el.find('li:not(:first)').remove();
};

Kobo.ViewModel.Base.prototype.applyBindings = function () {
  "use strict";

  ko.applyBindings(this, this.el);
};

Kobo.ViewModel.Base.prototype.fetchOffline = function (successCallBack) {
  "use strict";

  var items = [],
      i;

  if (!this.offlineItemId) {
    this.offlineItemId = 0;
  }

  var quantity = this.settings.dataPageSize;

  for (i = 0; i < this.fetchPageSizeOffline; i++) {
    if (this.offlineData.data.Items[i]) {
      this.offlineItemId++;
      items.push(this.offlineData.data.Items[i]);
    }
  }

  setTimeout(function () {
    successCallBack(items);
  }, 100);
};

Kobo.ViewModel.Base.prototype.preprocessData = function (data) {
  "use strict";

  return data;
};
/**
 * Sends a request for data for items from startIndex to endIndex.
 * @param successCallBack
 * @param {{startingIndex: number, endIndex: number}} options
 */


Kobo.ViewModel.Base.prototype.fetchData = function (successCallBack, options) {
  "use strict";

  if (this.isOffline) {
    return this.fetchOffline.apply(this, arguments);
  }

  var self = this,
      url = self.url = self.formatFetchUrl(self.urlPattern, options.startingIndex, options.endIndex, self.settings.dataPageSize, self.settings.dataPageBaseNumber);
  Kobo.Ajax({
    url: url,
    type: 'GET',
    success: function success(results) {
      if (results) {
        successCallBack(self.preprocessData(results), options);
      }
    },
    error: function error(xhr) {
      var error = xhr;
    }
  });
};
/**
 * Returns url from a given url pattern with {0} as pageNumber and {1} as pageSize.
 * @param urlPattern Url like "http://.../LoadMoreFeatureListItems?listId=ded1da05...&PageNumber={0}&ResultsPerPage={1}
 * @param startingIndex Index number of item to start from based off #0.
 * @param endIndex Index number of the last item.
 * @param pageSize
 * @param pageStartBase
 * @return {String} url
 */


Kobo.ViewModel.Base.prototype.formatFetchUrl = function (urlPattern, startingIndex, endIndex, pageSize, pageStartBase) {
  "use strict";

  var pageInfo = this.getPageInfo(startingIndex, endIndex, pageSize, pageStartBase),
      url = Kobo.Utilities.stringFormat(urlPattern, pageInfo.pageNumber, pageInfo.pageSize);
  return url;
};
/**
 * Returns page info based off page #startBase to include the given startingIndex of an item inside the data page.
 * @param {int} firstIndex Required index of the first item (based off #0) to be included into data page.
 * @param {int} lastIndex Required index of the last item (based off #0) to be included into data page.
 * @param {int} pageSize
 * @param {int} pageStartBase To start page number with (e.g. 0 or 1).
 * @return {{pageNumber: int, pageSize: int}} pageNumber
 */


Kobo.ViewModel.Base.prototype.getPageInfo = function (firstIndex, lastIndex, pageSize, pageStartBase) {
  "use strict";

  pageStartBase = pageStartBase || 0;
  var i,
      pageNumber,
      finalPageSize = pageSize,
      found = false;

  for (i = pageSize; i <= this.settings.totalNumberOfItems && !found; i++) {
    found = this.checkPageInfo(firstIndex, lastIndex, i, pageStartBase);
    finalPageSize = i;
  }

  pageNumber = this.getPageNumber(firstIndex, finalPageSize, pageStartBase);
  return {
    pageNumber: pageNumber,
    pageSize: finalPageSize
  };
};

Kobo.ViewModel.Base.prototype.checkPageInfo = function (firstIndex, lastIndex, pageSize, pageStartBase) {
  "use strict";

  return this.getPageNumber(firstIndex, pageSize, pageStartBase) === this.getPageNumber(lastIndex, pageSize, pageStartBase);
};

Kobo.ViewModel.Base.prototype.getPageNumber = function (index, pageSize, pageStartBase) {
  "use strict";

  return Math.floor(index / pageSize) + pageStartBase;
};