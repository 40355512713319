"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/*global Kobo,$*/
Kobo.Class = function () {
  "use strict";
  /*
   inherit via:
   Kobo.[class name].apply(this, arguments)
   */
  // add scope

  this.instanceEvents = {}; // private properties are added as variables
  // public properties are added to "scope"

  this.type = "Class";
};

Kobo.Class.prototype = Kobo.chainPrototype(Kobo.Error.prototype);

Kobo.Class.prototype.setType = function (newType) {
  "use strict";

  this.type += "." + newType;
};

Kobo.Class.prototype.bindEvents = function (events) {
  "use strict";

  var key;

  for (key in events) {
    if (events.hasOwnProperty(key)) {
      this.subscribe(key, events[key]);
    }
  }

  Kobo.deepCopy(this.instanceEvents, events);
};

Kobo.Class.prototype.unbindEvents = function (events) {
  "use strict";

  var key;

  for (key in events) {
    if (events.hasOwnProperty(key)) {
      this.unSubscribe(key, events[key]);
    }
  }
};

Kobo.Class.prototype.create = function create() {
  "use strict";
};

Kobo.Class.prototype.destroy = function destroy() {
  "use strict";

  this.unbindEvents(this.instanceEvents);
};
/**
 * Sets defaults properly if the class is intended to be inherited from.
 * @param {object} defaults Object with key-value default settings
 * @example:
 *          var ParentClass = function () {
 *              this.setDefaults({
 *                  param1: 1,
 *                  param2: 2
 *              });
 *          }
 *          var ChildClass = function () {
 *              this.setDefaults({
 *                  param1: 10,
 *                  param3: 20
 *              });
 *              ParentClass.apply(this, arguments);
 *          }
 *          var child = new ChildClass;
 *          console.dir( child.defaults );
 *          // will print:
 *          // { param1: 10, param2: 2, param3: 20 }
 */


Kobo.Class.prototype.setDefaults = function (defaults) {
  "use strict";

  if (!this.defaults) {
    this.defaults = {};
  }

  this.defaults = _objectSpread(_objectSpread({}, this.defaults), defaults);
};