"use strict";

/*global Kobo, ko */
Kobo.Gizmo.KoboLoveAutoRenewalWithPoints = function () {
  "use strict";

  Kobo.Gizmo.apply(this, arguments);
  this.setType("KoboLoveAutoRenewalWithPoints");
  var self = this,
      $element,
      $checkbox,
      $addToElement,
      classWhenHidden,
      renewURL;

  function addListeners() {
    $checkbox.change(function () {
      var newStatus = $checkbox.is(':checked') || false;
      postToServer(renewURL, {
        newStatus: newStatus
      });
    });

    Kobo._mediator.subscribe('koboLove::autoRenewToggled', function (_, _ref) {
      var detail = _ref.detail;

      if (detail.isAutoRenewOn) {
        $addToElement.removeClass(classWhenHidden);
      } else {
        $addToElement.addClass(classWhenHidden);
      }
    });
  }

  function postToServer(url, data) {
    $checkbox.prop("disabled", true);
    return Kobo.$.ajax({
      method: "POST",
      url: url,
      data: data,
      dataType: 'json',
      cache: false,
      complete: function complete() {
        $checkbox.prop("disabled", false);
      }
    });
  }

  function init() {
    $element = self.$el;
    $addToElement = $("." + self.settings.addClassTo);
    classWhenHidden = self.settings.classWhenHidden;
    renewURL = self.settings.setAutoRenewalUrl;
    $checkbox = $element.find("input[type='checkbox']");
    addListeners();
  }

  init();
};

Kobo.Gizmo.KoboLoveAutoRenewalWithPoints.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);