"use strict";

/*global Kobo*/
Kobo.Error = function () {
  "use strict";

  var self = this,
      init;
  self.errorLog = [];
  /**
   * Kobo.Error.prototype.log - logs an error
   * @param {String} args.detail.errorType
   * @param {String} args.detail.className
   * @param {Element} args.detail.instance
   * @param {String} [args.detail.errorDetails]
   */

  self.logError = function (e, args) {
    self.errorLog.push({
      type: args.detail.errorType,
      name: args.detail.className,
      instance: args.detail.instance,
      time: new Date().toString(),
      details: args.detail.errorDetails || ""
    });
  };

  init = function init() {
    self.register('Kobo.error');
    self.subscribe('Kobo.error', self.logError);
  };

  init();
};

Kobo.Error.prototype = Kobo.chainPrototype(Kobo.Mediator.prototype);

Kobo.Error.prototype.error = function (errorType, className, instance, errorDetails) {
  "use strict";

  this.fire('Kobo.error', {
    errorType: errorType,
    className: className,
    instance: instance,
    errorDetails: errorDetails
  });
};