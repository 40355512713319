"use strict";

ko.bindingHandlers.displayBreakpoint = ko.bindingHandlers.displayBreakpoint || {
  init: function init(element, valueAccessor) {
    Kobo._mediator.screenSizeObservable.subscribe(_updateBreakpoint);

    _updateBreakpoint();

    function _updateBreakpoint() {
      var breakpoint = Kobo._mediator.screenSizeObservable();

      var propertyToSet = valueAccessor();
      propertyToSet(breakpoint);
    }
  }
};