"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

/*globals Kobo,ko*/
if (_typeof(Kobo.Mediator) !== "object") {
  /**
   * Kobo.Mediator
   */
  Kobo.Mediator = function () {
    "use strict";

    var self = this,
        onResizeTimeout = null,
        onScrollTimeout = null,
        setupEvents,
        init;
    self.defaultEventNames = {
      resize: 'resize',
      scroll: 'scroll'
    };
    self.mediatorObject = Kobo.$(document.createElement("div"));
    self.mediatorObject._registeredEvents = {};

    self.unbindAll = function (eventName) {
      if (typeof self.mediatorObject._registeredEvents[eventName] === "string" && self.mediatorObject._registeredEvents[eventName] === "registered") {
        Kobo.$(self.mediatorObject).off(eventName);
      }
    };

    self.screenSize = '';
    self.screenSizeObservable = ko.observable();

    self.determineBreakpoint = function () {
      return Kobo.$html.css('font-family').replace(/"/g, "");
    };

    self.updateScreenSizeClassname = function (screenSize) {
      if (screenSize !== self.screenSize) {
        Kobo.$html.removeClass(self.screenSize);
        self.screenSize = screenSize;
        self.screenSizeObservable(screenSize);
        Kobo.$html.addClass(screenSize);
      }

      return screenSize;
    };

    setupEvents = function setupEvents() {
      Kobo.$window.on('resize', function () {
        // To reduce the number of onResize functions that fire, implement a delay that will only fire once the time interval (ms) has been met
        var timeInterval = 75;
        window.clearTimeout(onResizeTimeout);
        onResizeTimeout = window.setTimeout(function () {
          var screenSize = self.determineBreakpoint();
          self.updateScreenSizeClassname(screenSize);
          self.mediatorObject.trigger({
            "type": self.defaultEventNames.resize,
            "currentSize": screenSize
          });
        }, timeInterval);
      });
      Kobo.$window.on('scroll', function () {
        var timeInterval = 75,
            scrollTop = Kobo.$window.scrollTop(),
            scrollBottom = scrollTop + Kobo.$window.height();
        window.clearTimeout(onScrollTimeout);
        onScrollTimeout = window.setTimeout(function () {
          self.mediatorObject.trigger({
            "type": self.defaultEventNames.scroll,
            "scrollTop": scrollTop,
            "scrollBottom": scrollBottom
          });
        }, timeInterval);
      });
    };

    init = function init() {
      setupEvents();
      self.updateScreenSizeClassname(self.determineBreakpoint());
    };

    init();
  };
}

(function () {
  "use strict";

  var mediator, mediatorObject, registeredEvents;

  function eventIsRegistered(eventName) {
    return typeof registeredEvents[eventName] === "string" && registeredEvents[eventName] === "registered";
  }

  Kobo.Mediator.prototype = {
    currentBreakpoint: function currentBreakpoint() {
      return mediator.determineBreakpoint();
    },
    events: {
      onResize: function onResize(callBack) {
        mediatorObject.on(mediator.defaultEventNames.resize, callBack);
      },
      offResize: function offResize(callBack) {
        mediatorObject.off(mediator.defaultEventNames.resize, callBack);
      },
      onScroll: function onScroll(scope, callBack) {
        if (Kobo.Object.typeOf(scope) === "function") {
          callBack = scope;
          scope = '';
        }

        mediatorObject.on(mediator.defaultEventNames.scroll + scope, callBack);
      },
      // JS - ugly, but it works for now...
      offScroll: function offScroll(scope, callBack) {
        if (Kobo.Object.typeOf(scope) === "function") {
          callBack = scope;
          scope = '';
        }

        mediatorObject.off(mediator.defaultEventNames.scroll + scope, callBack);
      }
    },
    register: function register(eventName) {
      if (!registeredEvents[eventName] || registeredEvents[eventName] === "unregistered") {
        registeredEvents[eventName] = "registered";
      }

      return registeredEvents[eventName];
    },
    unRegister: function unRegister(eventName) {
      if (eventIsRegistered(eventName)) {
        registeredEvents[eventName] = "unregistered";
        mediator.unbindAll(eventName);
      }

      return registeredEvents[eventName];
    },
    subscribe: function subscribe(eventName, callBack) {
      mediatorObject.on(eventName, callBack);
    },
    unSubscribe: function unSubscribe(eventName, callBack) {
      if (eventIsRegistered(eventName)) {
        mediatorObject.off(eventName, callBack);
      }
    },
    fire: function fire(eventName, eventData, args) {
      if (eventIsRegistered(eventName)) {
        var isScoped = args && args.isScoped ? Kobo.Object.parseBool(args.isScoped) : true,
            eventScopeArray,
            eventScopeLength,
            eventScope,
            i;

        if (isScoped) {
          eventScopeArray = eventName.split('.');
          eventScopeLength = eventScopeArray.length;
          eventScope = "";

          for (i = 0; i < eventScopeLength; i++) {
            if (eventScope !== "") {
              eventScope += ".";
            }

            eventScope += eventScopeArray[i];
            mediatorObject.trigger(eventScope, [{
              detail: eventData,
              source: this
            }]);
          }
        } else {
          mediatorObject.trigger(eventName, [{
            detail: eventData
          }]);
        }
      }
    }
  };
  Kobo._mediator = mediator = new Kobo.Mediator();
  mediatorObject = mediator.mediatorObject;
  registeredEvents = mediatorObject._registeredEvents;
  Kobo._events = mediatorObject._registeredEvents;
  Kobo._events = registeredEvents;
})();