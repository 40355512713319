"use strict";

/*global Kobo*/
(function (Kobo) {
  function GizmoRichHeaderShoppingCartToggle(gizmo, options) {
    "use strict";

    Kobo.Gizmo.apply(this, arguments);
    this.setType("RichHeaderShoppingCart");
    this.invalidItemsDialog = null;
    this.dialogCookieName = 'hideCheckCart';
    this.$shoppingCartToggle = this.$gizmo.find('.' + this.settings.shoppingCartToggleSelector);
    this.$shoppingCartIcon = this.$gizmo.find('.' + this.settings.shoppingCartIconSelector);
    this.$shoppingCartCount = this.$shoppingCartIcon.find('em');
    this.$shoppingCartWidget = Kobo.$('.' + this.settings.shoppingCartWidgetSelector); // Bind callback methods

    this.shoppingCartUpdated = this.shoppingCartUpdated.bind(this);
    this.hideShoppingCart = this.hideShoppingCart.bind(this);
    this.toggleShoppingCart = this.toggleShoppingCart.bind(this);
    this.viewCartDialogAction = this.viewCartDialogAction.bind(this);
    this.closeDialogAction = this.closeDialogAction.bind(this); // debounce this callback

    this._hideShoppingCartWhenScrolledOutOfView = Kobo.Utilities.Events.debounce(this._hideShoppingCartWhenScrolledOutOfView.bind(this), 50);
    this.setupHandlers();
  }

  ;
  GizmoRichHeaderShoppingCartToggle.prototype = Kobo.chainPrototype(Kobo.Gizmo.prototype);

  GizmoRichHeaderShoppingCartToggle.prototype.showShoppingCart = function () {
    this.$shoppingCartToggle.removeClass(this.settings.shoppingCartCollapsedSelector);

    Kobo._mediator.fire('shoppingCart::toggle', {
      toggle: true
    });

    var currentScreenSize = Kobo._mediator.determineBreakpoint();

    if (currentScreenSize !== 'small') {
      Kobo._mediator.fire('richHeader::hideSmallSearch');
    }

    if (this.invalidItemsDialog) {
      this.closeDialogAction();
    }
  };

  GizmoRichHeaderShoppingCartToggle.prototype.hideShoppingCart = function (scrollToTop) {
    this.$shoppingCartToggle.addClass(this.settings.shoppingCartCollapsedSelector);

    Kobo._mediator.fire('shoppingCart::toggle', {
      toggle: false,
      scrollPage: scrollToTop
    });
  };

  GizmoRichHeaderShoppingCartToggle.prototype.toggleShoppingCart = function (event) {
    event.preventDefault();

    if (this.$shoppingCartToggle.hasClass(this.settings.shoppingCartCollapsedSelector)) {
      this.showShoppingCart();
      this.toggleHideShoppingCartOnScrollBehaviour(true);

      if (event.type === 'keydown') {
        var focusableItemsWidgetElements = Kobo.Utilities.findAllFocusableElements(this.$shoppingCartWidget);
        var firstFocusable = focusableItemsWidgetElements.toArray().filter(function (el) {
          return $(el).is(':visible');
        })[0]; // filtering non visible elements

        $(firstFocusable).trigger('focus');
      }
    } else {
      this.hideShoppingCart();
      this.toggleHideShoppingCartOnScrollBehaviour(false);
    }
  };

  GizmoRichHeaderShoppingCartToggle.prototype.shouldShowInvalidItemsDialog = function (items) {
    var cartContainsInvalidItems = false;

    for (var i = 0; i < items.length; i++) {
      if (items[i] && !items[i].availableInCurrentGeo) {
        cartContainsInvalidItems = true;
        break;
      }
    }

    if (cartContainsInvalidItems && this.$shoppingCartToggle.hasClass(this.settings.shoppingCartCollapsedSelector) && window.DynamicConfiguration.user.inHomeStoreFront) {
      return true;
    }

    return false;
  };

  GizmoRichHeaderShoppingCartToggle.prototype.shoppingCartUpdated = function (eventName, data) {
    var itemCount = data.detail.items && data.detail.items.length;

    if (itemCount > 0) {
      var count = itemCount > 99 ? '99+' : itemCount;
      this.$shoppingCartIcon.addClass(this.settings.shoppingCartShowIconSelector);
      this.$shoppingCartCount.text(count);
    } else {
      this.$shoppingCartIcon.removeClass(this.settings.shoppingCartShowIconSelector);
      this.$shoppingCartCount.text('');
    }

    if (Cookies.get(this.dialogCookieName) === undefined && data.detail.items) {
      if (this.shouldShowInvalidItemsDialog(data.detail.items)) {
        this.initInvalidItemsDialog();
        Cookies.set(this.dialogCookieName, 'true', {
          path: '/'
        });
      }
    }
  };

  GizmoRichHeaderShoppingCartToggle.prototype.destroy = function () {
    Kobo._mediator.unSubscribe('shoppingCartItems::updated', this.shoppingCartUpdated);

    Kobo._mediator.unSubscribe('richHeader::hideShoppingCart', this.hideShoppingCart);

    Kobo._mediator.unRegister('richHeader::hideShoppingCart');

    this.$shoppingCartToggle.off('click', this.toggleShoppingCart);
    this.toggleHideShoppingCartOnScrollBehaviour(false);
  };

  GizmoRichHeaderShoppingCartToggle.prototype._hideShoppingCartWhenScrolledOutOfView = function () {
    var isVisible = Kobo.Utilities.DomUtils.isWithinView(this.$shoppingCartWidget);

    if (!isVisible) {
      this.hideShoppingCart(false);
      this.toggleHideShoppingCartOnScrollBehaviour(false);
    }
  };

  GizmoRichHeaderShoppingCartToggle.prototype.toggleHideShoppingCartOnScrollBehaviour = function (hookToEvent) {
    if (hookToEvent) {
      Kobo.$window.on('scroll', this._hideShoppingCartWhenScrolledOutOfView);
    } else {
      Kobo.$window.off('scroll', this._hideShoppingCartWhenScrolledOutOfView);
    }
  };

  GizmoRichHeaderShoppingCartToggle.prototype.setupHandlers = function () {
    var _this = this;

    Kobo._mediator.subscribe('shoppingCartItems::updated', this.shoppingCartUpdated);

    Kobo._mediator.register('richHeader::hideShoppingCart');

    Kobo._mediator.subscribe('richHeader::hideShoppingCart', this.hideShoppingCart);

    this.$shoppingCartToggle.on('click', this.toggleShoppingCart);
    this.$shoppingCartToggle.on('keydown ', function (e) {
      if (e.keyCode === Kobo.KeyCodes.SPACE || e.keyCode === Kobo.KeyCodes.RETURN) {
        _this.toggleShoppingCart(e);
      }
    });
  };

  GizmoRichHeaderShoppingCartToggle.prototype.initInvalidItemsDialog = function () {
    var resourceStrings = window.DynamicConfiguration.resourceStrings;
    this.invalidItemsDialog = new Kobo.Popup({
      parent: this.$el,
      layout: 'dialog',
      title: resourceStrings.checkCart,
      content: resourceStrings.invalidCartItems,
      buttons: [new Kobo.Controls.Dialog.ButtonVM({
        label: resourceStrings.viewCart,
        action: this.viewCartDialogAction
      }), new Kobo.Controls.Dialog.ButtonVM({
        label: resourceStrings.close,
        action: this.closeDialogAction
      })],
      ariaLive: 'assertive',
      role: 'alertdialog',
      position: {
        rightAligned: true,
        top: '90%'
      },
      width: '280px',
      style: {
        'zIndex': '999'
      }
    });
    this.invalidItemsDialog.show();
  };

  GizmoRichHeaderShoppingCartToggle.prototype.viewCartDialogAction = function () {
    this.showShoppingCart();
    this.closeDialogAction();
  };

  GizmoRichHeaderShoppingCartToggle.prototype.closeDialogAction = function () {
    this.invalidItemsDialog.hide();
  };

  Kobo.Gizmo.RichHeaderShoppingCartToggle = GizmoRichHeaderShoppingCartToggle;
})(Kobo);