"use strict";

/*global Kobo */

/**
 * Manages vertical lazy loading for images
 */
// singleton
Kobo.LazyLoad = function () {
  'use strict';

  var lowQualityImagesClass = 'general-lazy';
  var emptyImagesClass = 'lazy';
  var rootMargin = '500px';

  var imageCallback = function imageCallback(entries, observer, cssClass) {
    [].slice.call(entries).forEach(function (entry) {
      if (entry.isIntersecting) {
        var lazyImage = entry.target;

        if (lazyImage.tagName === 'PICTURE') {
          var fallbackImage = lazyImage.querySelector('img');
          var sources = lazyImage.querySelectorAll('source');

          for (var i = 0; i < sources.length; i++) {
            sources[i].srcset = sources[i].dataset.lazy;
          }

          fallbackImage.src = fallbackImage.dataset.lazy;
        } else {
          var srcSet = lazyImage.dataset.srcset;
          var img = new Image();

          img.onload = function () {
            lazyImage.src = img.src;
            lazyImage.classList.remove(cssClass);

            if (srcSet) {
              lazyImage.srcset = srcSet;
            }
          };

          img.src = lazyImage.getAttribute('data-lazy');

          if (srcSet) {
            img.srcset = srcSet;
          }
        }

        observer.unobserve(lazyImage);
      }
    });
  };

  var observeImages = function observeImages(parent, cssClass, root) {
    var images = parent.querySelectorAll('.' + cssClass);
    var lazyImageObserver = new IntersectionObserver(function (entries, observer) {
      return imageCallback(entries, observer, cssClass);
    }, {
      root: root,
      rootMargin: rootMargin
    });

    for (var i = 0; i < images.length; i++) {
      lazyImageObserver.observe(images[i]);
    }
  };

  var carouselObserver = function carouselObserver() {
    return {
      IntersectionObserver: new IntersectionObserver(function (entries, observer) {
        [].slice.call(entries).forEach(function (entry) {
          if (entry.isIntersecting) {
            observeImages(entry.target, emptyImagesClass, entry.target);
            observer.unobserve(entry.target);
          }
        });
      }, {
        rootMargin: rootMargin
      })
    };
  };

  var attachObserverToCarousels = function attachObserverToCarousels() {
    var carousels = document.querySelectorAll('[lazy-load-parent]');
    var intersectionObserver = carouselObserver().IntersectionObserver;

    for (var i = 0; i < carousels.length; i++) {
      intersectionObserver.observe(carousels[i]);
    }
  };

  var lazyLoadImages = function lazyLoadImages(cssClass) {
    observeImages(document, cssClass);
  };

  var addToObserver = function addToObserver(carouselToObserver) {
    carouselObserver().IntersectionObserver.observe(carouselToObserver);
  };

  var lazyLoadCb = function lazyLoadCb() {
    lazyLoadImages(lowQualityImagesClass);
    attachObserverToCarousels();
    lazyLoadCb.addToObserver = addToObserver;
  };

  window.addEventListener('DOMContentLoaded', lazyLoadCb); /// Added for testing purposes

  return lazyLoadCb;
}();