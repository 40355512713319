"use strict";

/**
 * An expandable that is collapsed when the user clicks outside of it.
 * @param el
 * @param options
 * @constructor
 */
Kobo.Gizmo.Expandable.Popup = function (el, options) {
  "use strict";

  var self = this,
      onDocumentClick;
  this.setDefaults({
    ariaHasPopup: true
  });
  Kobo.Gizmo.Expandable.apply(this, arguments);
  self.setType("Popup");

  onDocumentClick = function onDocumentClick(e) {
    if (!Kobo.$.contains(el, e.target)) {
      self.viewModel.$button.click();
    }
  };

  self.subscribe("gizmosInitialized", function () {
    if (self.viewModel) {
      self.viewModel.isCollapsed.subscribe(function () {
        if (self.currentBreakpoint() === "large" || self.currentBreakpoint() === "jumbo") {
          if (!self.viewModel.isCollapsed()) {
            setTimeout(function () {
              Kobo.$(document).on("click", onDocumentClick);
            }, 150);
          } else {
            Kobo.$(document).off("click", onDocumentClick);
          }
        }
      });
    }
  });
};

Kobo.Gizmo.Expandable.Popup.prototype = Kobo.chainPrototype(Kobo.Gizmo.Expandable.prototype);