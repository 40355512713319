"use strict";

/*global Kobo,DynamicConfiguration*/

/*
 * Kobo.Gizmo.Collapsible.HasHeader
 * @description Creates a collapsible region that can animate to open and closed states using events, triggered by an internal toggle. This extends Gizmo.Collapsible, which in turn relies on Collapsible.js. Kobo.Gizmo.Collapsible.HasHeader expects to receive a toggle element, which is contained within the gizmo object. It treats the last child within the gizmo object as the collapsible region. This means that you could potentially use this gizmo to display an always visible region, a toggle element, and a collapsible region. 
 * @param {element} el
 * @param {object} options
 */
Kobo.Gizmo.Collapsible.HasHeader = function () {
  'use strict';

  var self = this,
      init,
      toggleCollapsible,
      toggleButtonText,
      closeButtonText,
      _closeAndUpdateState,
      removeFocusFromButton,
      setupEvents,
      setupInternalEvents,
      setupTransitionalEvents,
      updateAria,
      updateState,
      closeOtherCollapsibles,
      button,
      expandable,
      internalTrigger;

  Kobo.Gizmo.Collapsible.apply(this, arguments);

  toggleCollapsible = function toggleCollapsible(e) {
    e.stopPropagation();

    Kobo._mediator.fire('collapsible::toggle', {
      name: self.options.name
    });

    Kobo._mediator.fire('collapsible::isOpen', {
      name: self.options.name,
      callback: updateState
    });

    if (self.options.closeOtherCollapsibles === true) {
      Kobo._mediator.fire('collapsible::isOpen', {
        name: self.options.name,
        callback: closeOtherCollapsibles
      });
    }

    if (self.options.closeOnClickAnywhere === true) {
      Kobo._mediator.fire('collapsible::isOpen', {
        name: self.options.name,
        callback: setupTransitionalEvents
      });
    }

    if (self.options.setButtonTextToInternalItemText === true) {
      if (self.options.internalElementClass && e.srcElement.className === self.options.internalElementClass) {
        button.text(e.srcElement.text);
      }
    }
  };

  _closeAndUpdateState = function closeAndUpdateState() {
    Kobo._mediator.fire('collapsible::close', {
      name: self.options.name
    });

    updateAria(false);

    if (self.options.toggleButtonText) {
      toggleButtonText();
    }

    Kobo.$body.off('click', _closeAndUpdateState);
  };

  removeFocusFromButton = function removeFocusFromButton() {
    button.trigger('blur');
  };

  setupEvents = function setupEvents() {
    button.on('click', toggleCollapsible);
    button.on('mouseup', removeFocusFromButton); // This removes the focus from the toggle when clicked by mouse. This differentiates keyboard and mouse nav.
  };

  setupInternalEvents = function setupInternalEvents() {
    internalTrigger.on('click', toggleCollapsible);
  };

  setupTransitionalEvents = function setupTransitionalEvents(state) {
    if (state) {
      Kobo.$body.on('click', _closeAndUpdateState);
    }
  };

  updateState = function updateState(state) {
    updateAria(state);

    if (self.options.toggleButtonText) {
      toggleButtonText();
    }
  };

  updateAria = function updateAria(state) {
    if (state) {
      button.attr('aria-expanded', true);
    } else {
      button.attr('aria-expanded', false);
    }
  };

  toggleButtonText = function toggleButtonText() {
    var state;

    if (!closeButtonText) {
      closeButtonText = button.text();
    }

    Kobo._mediator.fire('collapsible::isOpen', {
      name: self.options.name,
      callback: function callback(openState) {
        state = openState;
      }
    });

    if (state) {
      button.text(DynamicConfiguration.resourceStrings[self.settings.toggleButtonText]);
    } else {
      button.text(closeButtonText);
    }
  };

  closeOtherCollapsibles = function closeOtherCollapsibles(state) {
    if (state) {
      Kobo._mediator.fire('collapsible::closeAllOther', {
        name: self.options.name
      });
    }
  };

  init = function init() {
    if (!self.options.toggleClass) {
      throw new Error('Collapsible.HasHeader requires a toggle!');
    }

    button = self.$el.find('.' + self.options.toggleClass);

    if (!button.attr('aria-controls')) {
      throw new Error('Accessibility constraints violated: toggle must specify aria-controls (' + self.settings.name + ')');
    }

    button.attr('aria-expanded', false);
    expandable = self.$el.find('#' + button.attr('aria-controls'));
    setupEvents();

    if (self.options.internalClickIsToggle === true) {
      if (!self.options.internalElementClass) {
        throw new Error('Internal element class must be specified (' + self.settings.name + ')');
      }

      internalTrigger = expandable.find('.' + self.options.internalElementClass);
      setupInternalEvents();
    }
  };

  init();
};

Kobo.Gizmo.Collapsible.HasHeader.prototype = Kobo.chainPrototype(Kobo.Gizmo.Collapsible.prototype);