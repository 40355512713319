"use strict";

/*global Kobo*/
(function (Kobo) {
  /**
   * Kobo.GizmoFactory - Base constructor for all gizmos. Creates a Global Gizmo object with list of active gizmos for debugging
   * @constructor
   */
  function GizmoFactory() {
    "use strict"; // Inherits from default class

    var _this = this;

    Kobo.Class.call(this);
    this.setType("GizmoFactory");
    /**
     * gizmoList - Object containing pointers to all instantiated gizmos
     * @type {{}}
     */

    this.gizmoList = {};
    this.gizmoList.total = 0;
    /**
     * Initialize function, should only be called on page load
     */

    var init = function init() {
      _this.register("gizmosInitialized");
    };

    init();
  }

  GizmoFactory.prototype = Kobo.Class.prototype;
  /**
   * initContext - validates and calls to instantiate all gizmos (using the factory) that are in a given context
   * @param context (element/jQuery element)
   * @param ancestors
   */

  GizmoFactory.prototype.initContext = function (context, ancestors) {
    // Validate that the object is an element or a jQuery object
    if (context instanceof Kobo.$ || context.nodeType === 1) {
      // Find all gizmos in the element
      var gizmoList = Kobo.$('[data-kobo-gizmo]', context);
      this.gizmoFactory(gizmoList, ancestors);
    }
  };
  /**
   * gizmoFactory - iterates through all gizmos in a gizmoList and instantiates them.
   * @param gizmoList
   * @param ancestors
   */


  GizmoFactory.prototype.gizmoFactory = function (gizmoList, ancestors) {
    var _this2 = this;

    gizmoList.each(function (i, gizmoEl) {
      // Initialize the current gizmo being iterated on
      _this2.initGizmo(gizmoEl, ancestors);
    });
  };
  /**
       * buildGizmo - function to add gizmo path to the gizmoList, and instantiate the gizmo
       * @param currentGizmo
       * @param ancestors
       */


  GizmoFactory.prototype.buildGizmo = function (currentGizmo, ancestors) {
    if (!currentGizmo.initialized) {
      var // String of the gizmo's type
      currentGizmoType = currentGizmo.getAttribute('data-kobo-gizmo'),
          // Turn the string in to an array for iteration
      currentGizmoTypeArray = currentGizmoType.split('.'),
          // Static length for use in the while evaluation
      currentGizmoTypeArrayLength = currentGizmoTypeArray.length,
          // Code path to the gizmo's Class, this will become more specific through iteration
      CurrentGizmoTypePath = Kobo.Gizmo,
          // Used to build the Global _gizmo pointers to all gizmos on a page
      currentPath = this.gizmoList,
          // Flag used to evaluate if an object is complete
      isObject = false,
          index = 0,
          currentGizmoConfig,
          newParents,
          detailString; // Check if this gizmo type actually exists

      while (CurrentGizmoTypePath[currentGizmoTypeArray[index]] && index < currentGizmoTypeArrayLength) {
        // gizmo path exists, set next value for next iteration
        CurrentGizmoTypePath = CurrentGizmoTypePath[currentGizmoTypeArray[index]]; // If new gizmo type exists, create new array to put that type of gizmo in the Global _gizmo object

        if (!currentPath[currentGizmoTypeArray[index]]) {
          currentPath[currentGizmoTypeArray[index]] = [];
        } // set the new path for the Global _gizmo object for the nextiteration


        currentPath = currentPath[currentGizmoTypeArray[index]];
        index++; // True if done

        isObject = currentGizmoTypeArrayLength === index;
      }

      var newGizmo = false; // if true we're done and the object is ready for instantiation

      if (isObject) {
        try {
          currentGizmoConfig = JSON.parse(currentGizmo.getAttribute('data-kobo-gizmo-config')) || {};
        } catch (e) {
          Kobo.log("Error parsing gizmo config: " + currentGizmo.getAttribute('data-kobo-gizmo-config') + "Exception:" + e);
          currentGizmoConfig = {};
        }

        try {
          newGizmo = new CurrentGizmoTypePath(currentGizmo, currentGizmoConfig);
          this.gizmoList.total++;
          currentPath.push(newGizmo); // creates an empty array if not constructed

          if (ancestors === undefined) {
            ancestors = [];
          } // assign ancestors to current initiated gizmo


          newGizmo.ancestors = ancestors; // clone the ancestors array so that we don't keep appending to the same array

          newParents = ancestors.slice(0); // adds the current gizmo to a chain so that any descendant receives the chain of ancestors

          newParents.push(newGizmo);
          this.initContext(currentGizmo, newParents);
        } catch (e) {
          console.error("Error building gizmo: " + currentGizmoType + ". Exception: " + e);
          throw e;
        }
      } else {
        detailString = "Tried to create a gizmo object and failed. Of type: " + currentGizmoType;
        this.error("GizmoCreation", "GizmoFactory", this, detailString);
      }

      return newGizmo;
    }
  };
  /**
   * initGizmo - public method that validates gizmo and calls to build a single gizmo
   * @param gizmo (element/jQuery element)
   * @param ancestors
   */


  GizmoFactory.prototype.initGizmo = function (gizmo, ancestors) {
    if (gizmo instanceof Kobo.$) {
      gizmo = gizmo.get(0);
    }

    if (gizmo.nodeType === 1) {
      var hasAttr = !!gizmo.attributes['data-kobo-gizmo'];

      if (hasAttr) {
        var newGizmo = this.buildGizmo(gizmo, ancestors);

        if (ancestors && newGizmo) {
          ancestors[ancestors.length - 1].descendants.push(newGizmo);
        }
      }
    }
  };

  Kobo.GizmoFactory = GizmoFactory;
})(Kobo);